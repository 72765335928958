import axios from 'axios';
import { TEST_SAVING, TEST_UPDATED, TASK_SAVING } from './types';
import { setAlert } from './alert';

// Save Task
export const saveTask = (task, test) => async dispatch => {
  if (task && test) {
    dispatch({ type: TEST_SAVING, payload: true });
    dispatch({ type: TASK_SAVING, payload: true });

    try {
      const config = { headers: { 'Content-Type': 'application/json' } };
      const body = JSON.stringify(task);

      let tasks = test.tasks;

      // Update Task
      if (task._id) {
        const result = await axios.put(`/api/task/${task._id}`, body, config);
        let newTask = result.data;
        let taskIndex;
        tasks.forEach((t, index) => {
          if (t._id === newTask._id) {
            taskIndex = index;
          }
        });

        tasks.splice(taskIndex, 1, newTask);
      } else {
        const result = await axios.post(`/api/test/${test._id}/task`, body, config); // Gives the TestModel with populating the 'Task'
        tasks.push(result.data);
      }

      dispatch({ type: TEST_UPDATED, payload: test });
      dispatch({ type: TASK_SAVING, payload: false });

      let alertMsg = task._id ? 'Task updated successfully.' : 'Task created successfully.';
      dispatch(setAlert(alertMsg, 'success'));
    } catch (error) {
      console.log(error);
    }
  }
};

// Delete Task
export const deleteTask = (taskId, test) => async dispatch => {
  if (taskId && test) {
    dispatch({ type: TEST_SAVING, payload: true });
    dispatch({ type: TASK_SAVING, payload: true });

    try {
      await axios.delete(`/api/test/${test._id}/task/${taskId}`);

      // Remove Deleted Task
      test.tasks = test.tasks.filter(task => task._id !== taskId);

      dispatch({ type: TEST_UPDATED, payload: test });
      dispatch({ type: TASK_SAVING, payload: false });

      dispatch(setAlert('Task deleted successfully.', 'success'));
    } catch (error) {
      console.log(error);
    }
  }
};
