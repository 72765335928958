import React, { Fragment, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Container, Grid, Button, GridRow, Tab, Image, Form, Popup, Input, Icon } from 'semantic-ui-react';
import { TEST_SETUP_ICON, TEST_DTAILS_ICON, TEST_MESSAGES_ICON, TEST_RECRUIT_ICON, TEST_QUESTIONNAIRE_ICON } from '../../utills/icons';
import { getTestStatuslabelIcon, isTestLaunchable } from '../../utills/testFunctions';
import { DateInput } from 'semantic-ui-calendar-react';
import Moment from 'react-moment';
import moment from 'moment';
import Tree from './Tree';
import Tasks from './Tasks';
import Questionnaire from './Questionnaire';
import UXLoader from '../layouts/UXLoader';
import { saveTestState, getTest, saveTest, launchTest, deactivateTest } from '../../actions/test';
import { copyToClipboard } from '../../utills/common';
import { setAlert } from '../../actions/alert';
import { TEST_STATUS_ACTIVE, TEST_STATUS_INACTIVE, TEST_STATUS_DRAFT } from '../../actions/constants';
import tooltips from '../../utills/tooltips';

const NewTest = ({
  match: { params },
  test: { test, isSaving },
  saveTestState,
  getTest,
  saveTest,
  launchTest,
  deactivateTest,
  setAlert
}) => {
  // Test is in Preview Mode
  const isPreview =
    (params.action && params.action === 'preview') ||
    (test && (test.status === TEST_STATUS_ACTIVE || test.status === TEST_STATUS_INACTIVE))
      ? true
      : false;

  // Test is in Edit Mode
  const isEdit = params.action && params.action === 'edit' ? true : false;

  // Get the Test
  useEffect(() => {
    getTest(params.testId, isEdit);

    // Reset State
    return () => saveTestState(null);
  }, [getTest, params.testId, isEdit, saveTestState]);

  const inputRef = useRef(null);

  // Save some default values
  test = test || {};
  test.name = test.name || '';
  test.messages = test.messages || { maxChars: 600, greeting: '', thankyou: '', instructions: '' };
  test.recruitLink = window.location.origin + '/treestudy/' + test._id;

  // Save Test State
  const onChange = d => {
    if (d.name === 'greeting' || d.name === 'thankyou' || d.name === 'instructions') {
      test.messages[d.name] = d.value;
    } else {
      test[d.name] = d.value;
    }

    saveTestState(test);

    if (test.name && d.name === 'endTime') {
      saveTest(test);
    }
  };

  const copyShareLink = elementRef => {
    copyToClipboard(elementRef);
    setAlert('Link copied to clipboard!');
  };

  const messages = test.messages;
  const panes = [
    {
      menuItem: {
        key: 'test-details',
        content: (
          <div className="tab-title">
            <Image src={TEST_DTAILS_ICON} size="mini" verticalAlign="middle" /> Test Details
          </div>
        )
      },
      render: () => (
        <Tab.Pane>
          <Form className="p-2">
            <Form.Field width={8}>
              <label>
                What would you name this study?{' '}
                {tooltips.testName && (
                  <Popup content={tooltips.testName} trigger={<Icon name="help circle" size="tiny" />} position="top center" />
                )}
              </label>
              <Form.Input
                type="text"
                placeholder="Enter your test name here..."
                name="name"
                value={test.name}
                fluid
                onChange={(e, d) => onChange(d)}
                onBlur={() => test.name && saveTest(test)}
                readOnly={isPreview}
                maxLength={40}
              />
            </Form.Field>
            <Form.Field width={8}>
              <label>
                Set up a date for test completion{' '}
                {tooltips.testExpireDate && (
                  <Popup content={tooltips.testExpireDate} trigger={<Icon name="help circle" size="tiny" />} position="top center" />
                )}
              </label>
              <DateInput
                name="endTime"
                placeholder="YYYY/MM/DD"
                value={test.endTime ? moment(test.endTime).format('YYYY/MM/DD') : ''}
                minDate={new Date()}
                dateFormat="YYYY/MM/DD"
                iconPosition="right"
                closable
                fluid
                animation="fade"
                popupPosition="top right"
                onChange={(e, d) => onChange(d)}
                autoComplete="off"
                readOnly={isPreview}
              />
            </Form.Field>
          </Form>
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'test-setup',
        disabled: !test._id,
        content: (
          <div className="tab-title">
            <Image src={TEST_SETUP_ICON} size="mini" verticalAlign="middle" /> Test Setup
          </div>
        )
      },
      render: () => (
        <Tab.Pane>
          <Grid className="pr-2">
            <Grid.Row>
              <Grid.Column width={12}>
                <Tree isPreview={isPreview} />
              </Grid.Column>
              <Grid.Column width={4} className="p-0">
                <Tasks isPreview={isPreview} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'messages',
        disabled: !test._id,
        content: (
          <div className="tab-title">
            <Image src={TEST_MESSAGES_ICON} size="mini" verticalAlign="middle" /> Messages
          </div>
        )
      },
      render: () => (
        <Tab.Pane>
          <Form className="p-2">
            <Form.Field width={11}>
              <label>
                Greeting Message{' '}
                <Popup
                  content={tooltips.testMessages.greeting}
                  trigger={<Icon name="help circle" size="tiny" />}
                  position="top center"
                />
              </label>
              <Form.TextArea
                placeholder="Greeting Message"
                name="greeting"
                value={messages.greeting}
                rows="6"
                onChange={(e, d) => onChange(d)}
                maxLength={messages.maxChars}
                onBlur={() => saveTest(test)}
                readOnly={isPreview}
              />
              <p className="text-right">{messages.maxChars - messages.greeting.length} Character left</p>
            </Form.Field>
            <Form.Field width={11}>
              <label>
                Thank You Message{' '}
                <Popup
                  content={tooltips.testMessages.thankYou}
                  trigger={<Icon name="help circle" size="tiny" />}
                  position="top center"
                />
              </label>
              <Form.TextArea
                placeholder="Thank You Message"
                name="thankyou"
                value={messages.thankyou}
                rows="6"
                onChange={(e, d) => onChange(d)}
                maxLength={messages.maxChars}
                onBlur={() => saveTest(test)}
                readOnly={isPreview}
              />
              <p className="text-right">{messages.maxChars - messages.thankyou.length} Character left</p>
            </Form.Field>
            <Form.Field width={11}>
              <Header as="h3">Instructions Contents</Header>
              <label>
                Message{' '}
                <Popup
                  content={tooltips.testMessages.instructions}
                  trigger={<Icon name="help circle" size="tiny" />}
                  position="top center"
                />
              </label>
              <Form.TextArea
                placeholder="Instructions Message"
                name="instructions"
                value={messages.instructions}
                rows="6"
                onChange={(e, d) => onChange(d)}
                maxLength={messages.maxChars}
                onBlur={() => saveTest(test)}
                readOnly={isPreview}
              />
              <p className="text-right">{messages.maxChars - messages.instructions.length} Character left</p>
            </Form.Field>
          </Form>
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'test-recruit',
        disabled: !test._id,
        content: (
          <div className="tab-title">
            <Image src={TEST_RECRUIT_ICON} size="mini" verticalAlign="middle" /> Recruit
          </div>
        )
      },
      render: () => (
        <Tab.Pane>
          <Form className="p-2">
            <Form.Field width={8}>
              <label>
                Share Link{' '}
                <Popup content={tooltips.testShareLink} trigger={<Icon name="help circle" size="tiny" />} position="top center" />
              </label>
              <Input type="text" placeholder="study link..." value={test.recruitLink} readOnly action fluid ref={inputRef}>
                <input />
                <Button primary className="auto-width" icon onClick={() => copyShareLink(inputRef)}>
                  <Icon name="copy" />
                </Button>
              </Input>
            </Form.Field>
          </Form>
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'test-questionnaire',
        disabled: true,
        content: (
          <div className="tab-title">
            <Image src={TEST_QUESTIONNAIRE_ICON} size="mini" verticalAlign="middle" /> Questionnaire
          </div>
        )
      },
      render: () => (
        <Tab.Pane>
          <Questionnaire isPreview={isPreview} />
        </Tab.Pane>
      )
    }
  ];

  if (params.testId && (!test || !test._id)) return <UXLoader />;

  return (
    <Fragment>
      <Container>
        <Grid className="m-0">
          <Grid.Row className="pt-3">
            <Grid.Column width="10" className="pl-0">
              <GridRow>
                <Header as="h2" className="d-inline">
                  {test.name ? test.name : 'Tree Testing Study'}
                </Header>
                <Image src={getTestStatuslabelIcon(test.status, false)} className="d-inline ml-2" />
                <small className="text-underline font-weight-italic ml-2">
                  {isSaving ? 'Saving...' : 'All changes will be saved automatically'}
                </small>
              </GridRow>
              <GridRow>
                <span className="mr-1">Created By</span> |{' '}
                <Moment className="ml-1 font-weight-bold" format="DD MMMM YYYY">
                  {test.startTime}
                </Moment>
              </GridRow>
            </Grid.Column>
            <Grid.Column textAlign="right" width="6">
              {test.status !== TEST_STATUS_DRAFT && (
                <Button color="green" as={Link} to={`/tests/report/${test._id}`}>
                  Report
                </Button>
              )}
              <Button
                basic
                color="black"
                className="bg-white"
                disabled={test.status !== TEST_STATUS_ACTIVE ? true : false}
                as={Link}
                to={`/treestudy/${test._id}/preview`}
              >
                Preview
              </Button>
              <Popup
                trigger={
                  <span>
                    <Button
                      primary
                      disabled={!isTestLaunchable(test)}
                      onClick={() => {
                        test.status !== TEST_STATUS_ACTIVE ? launchTest(test) : deactivateTest(test);
                      }}
                    >
                      {test.status !== TEST_STATUS_ACTIVE
                        ? test.status === TEST_STATUS_INACTIVE
                          ? 'Re-Launch'
                          : 'Launch'
                        : 'Unlaunch'}
                    </Button>
                  </span>
                }
                content={isTestLaunchable(test, true)}
                position="top center"
                disabled={isTestLaunchable(test)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <Container fluid>
        <Tab
          menu={{ fluid: true, vertical: true }}
          menuPosition="left"
          panes={panes}
          className="test-setup"
          grid={{ paneWidth: 13, tabWidth: 3 }}
          defaultActiveIndex={test.state === 1 ? 3 : 0}
        />
      </Container>
    </Fragment>
  );
};

NewTest.propTypes = {
  saveTestState: PropTypes.func.isRequired,
  getTest: PropTypes.func.isRequired,
  saveTest: PropTypes.func.isRequired,
  deactivateTest: PropTypes.func.isRequired,
  launchTest: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  test: PropTypes.object
};

const mapStateToProps = state => ({
  test: state.test
});

export default connect(
  mapStateToProps,
  { saveTestState, getTest, saveTest, launchTest, deactivateTest, setAlert }
)(NewTest);
