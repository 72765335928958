import React from 'react';
import { Modal, Button, Image } from 'semantic-ui-react';
import { CAUTION_ICON } from '../../utills/icons';

const UXConfirm = ({ confirmMessage, callback, buttons }) => (
  <Modal size="mini" open={confirmMessage ? true : false} onClose={() => callback(false)} className="confirm-modal" centered={false}>
    <Modal.Content scrolling>
      <Image src={CAUTION_ICON} centered />
      <p className="message">{confirmMessage}</p>
      <div className="text-center">
        <Button color="pink" onClick={() => callback(true)}>
          {buttons && buttons.ok ? buttons.ok : 'Delete'}
        </Button>
        <Button basic onClick={() => callback(false)} className="mr-0">
          {buttons && buttons.cancel ? buttons.cancel : 'Cancel'}
        </Button>
      </div>
    </Modal.Content>
  </Modal>
);

export default UXConfirm;
