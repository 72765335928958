// Generate Unique ID
export const uniqueID = () =>
  Math.random()
    .toString(36)
    .substr(2, 10);

export const copyToClipboard = elementRef => {
  if (elementRef) {
    elementRef.current.select();
    document.execCommand('copy');
  }
};
