import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Form } from 'semantic-ui-react';
// import { uniqueID } from '../../utills/common';
import { loadSurveys, saveSurvey, saveSurveyState, addQuestion } from '../../actions/survey';

const Survey = ({ surveyType, task, test, surveys, loadSurveys, saveSurvey, saveSurveyState, addQuestion }) => {
  // Load the Surveys
  useEffect(() => {
    loadSurveys(test, task);
  }, [loadSurveys, test, task]);

  const allSurveys = surveys[surveyType] || [];

  let questions;
  if (allSurveys.length > 0) {
    questions = allSurveys.map((item, index) => {
      const surveyIndex = index + 1;
      return { key: surveyIndex, text: `Question ${surveyIndex}`, value: surveyIndex };
    });
  } else {
    questions = [{ key: 1, text: 'Question 1', value: 1 }];
  }

  const TYPE_OPEN_TEXT = 0;
  const TYPE_CHECKBOX = 1;
  const TYPE_RADIO = 2;
  const optionTypes = [
    { key: TYPE_OPEN_TEXT, text: 'Open Text', value: TYPE_OPEN_TEXT },
    { key: TYPE_CHECKBOX, text: 'Checkbox', value: TYPE_CHECKBOX },
    { key: TYPE_RADIO, text: 'Radio', value: TYPE_RADIO }
  ];

  // Some Default Values
  const survey = surveys.survey || {};
  survey.surveyType = survey.surveyType || surveyType;
  survey.messages = survey.messages || '';
  survey.question = survey.question || '';
  survey.selectedQuestion = survey.selectedQuestion || 1;
  survey.optionType = survey.optionType || TYPE_OPEN_TEXT;

  const onChange = d => {
    if (d.name === 'optionType' || d.name === 'selectedQuestion') {
      survey[d.name] = d.value;
      saveSurvey(survey, test, task);
    } else {
      survey[d.name] = d.value.trim();
      saveSurveyState(survey);
    }
  };

  console.log(survey);

  return (
    <Form className="survey-form">
      <Form.TextArea
        label="Messages"
        name="messages"
        value={survey.message}
        width={16}
        onChange={(e, data) => onChange(data)}
        onBlur={() => saveSurvey(survey, test, task)}
      />
      <Form.Group>
        <Form.Select
          options={questions}
          width={6}
          placeholder="Select Question"
          name="selectedQuestion"
          defaultValue={survey.selectedQuestion}
          onChange={(e, data) => onChange(data)}
          onBlur={() => saveSurvey(survey, test, task)}
        />
        <Form.Field width={10} className="survey-actions">
          <span className="mr-2 pointer" onClick={e => addQuestion()}>
            <Icon name="add circle" /> Add Question
          </span>
          <Icon name="trash alternate" />
        </Form.Field>
      </Form.Group>
      <Form.TextArea
        name="question"
        width={16}
        value={survey.question}
        onChange={(e, data) => onChange(data)}
        onBlur={() => saveSurvey(survey, test, task)}
      />
      <Form.Select
        options={optionTypes}
        width={6}
        placeholder="Option Type"
        name="optionType"
        defaultValue={survey.optionType}
        onChange={(e, data) => onChange(data)}
      />
    </Form>
  );
};

Survey.propTypes = {
  surveys: PropTypes.object.isRequired,
  loadSurveys: PropTypes.func.isRequired,
  saveSurvey: PropTypes.func.isRequired,
  saveSurveyState: PropTypes.func.isRequired,
  addQuestion: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  surveys: state.survey
});

export default connect(
  mapStateToProps,
  { loadSurveys, saveSurvey, saveSurveyState, addQuestion }
)(Survey);
