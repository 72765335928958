import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Header, Container, Image, Grid, Form, Modal, Button } from 'semantic-ui-react';
import { verifyTest, saveParticipant } from '../../actions/study';
import countries from '../../utills/countries';
import { UXARMY_LOGO, GENDER_MALE, GENDER_FEMALE } from '../../utills/icons';
import UXLoader from '../layouts/UXLoader';
import '../../assets/css/study.css';

const TreeStudy = ({ match: { params }, study: { participant, messages }, verifyTest, saveParticipant }) => {
  const testId = params.testId;
  const isPreview = params.action && params.action === 'preview' ? true : false;

  // State Management
  const [state, setState] = useState({
    showModal: true,
    tos: false,
    showError: false,
    email: '',
    firstName: '',
    lastName: '',
    gender: '',
    nationality: ''
  });

  const { showModal, tos, showError, email, firstName, lastName, gender, nationality } = state;

  // Load Test
  useEffect(() => {
    verifyTest(testId, isPreview);
  }, [verifyTest, testId, isPreview]);

  const genderOptions = [
    { key: 1, text: 'Male', value: 1, image: { src: GENDER_MALE } },
    { key: 0, text: 'Female', value: 0, image: { src: GENDER_FEMALE } }
  ];

  const onChange = d => {
    const value = d.name === 'gender' ? d.value : d.value.trim();
    setState({ ...state, [d.name]: value });
  };

  const onSubmit = e => {
    e.preventDefault();
    setState({ ...state, setError: true });

    // submit form
    if (email && firstName && gender !== '' && nationality) {
      const formData = { email, firstName, lastName, gender, nationality };
      saveParticipant(formData, testId, isPreview);
    }
  };

  if (participant && participant.responseId) {
    return <Redirect to={`/treestudy/begin`} />;
  }

  if (!messages) {
    return <UXLoader />;
  }

  return (
    <Fragment>
      {isPreview && (
        <Container className="preview-mode p-2" fluid textAlign="center">
          You are currently in preview mode for the study. No data will be saved.
        </Container>
      )}
      <Grid className={`study no-navbar ${isPreview ? 'pt-4' : ''}`}>
        <Grid.Row className="study-header">
          <Grid.Column>
            <Image src={UXARMY_LOGO} size="small" />
            <Header as="h3" className="mt-2">
              Participant Details
              <Header.Subheader className="mt-1">Please kindly fill up your details before you proceed to the study.</Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="study-body">
          <Grid.Column width="8">
            <Form onSubmit={e => onSubmit(e)}>
              <Form.Input
                type="email"
                name="email"
                label="Email"
                placeholder="Email"
                fluid
                onChange={(e, data) => onChange(data)}
                error={showError && email.length === 0}
              />
              <Form.Group widths="equal">
                <Form.Input
                  type="text"
                  label="First Name"
                  name="firstName"
                  placeholder="First Name"
                  onChange={(e, data) => onChange(data)}
                  error={showError && firstName.length === 0}
                />
                <Form.Input
                  type="text"
                  label="Last Name"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={(e, data) => onChange(data)}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Select
                  options={genderOptions}
                  name="gender"
                  label="Gender"
                  placeholder="Gender"
                  onChange={(e, data) => onChange(data)}
                  error={showError && gender.length === 0}
                />
                <Form.Dropdown
                  options={countries}
                  selection
                  search
                  name="nationality"
                  label="Nationality"
                  placeholder="Nationality"
                  onChange={(e, data) => onChange(data)}
                  error={showError && nationality.length === 0}
                />
              </Form.Group>
              <Form.Field>
                <Form.Checkbox
                  label="I agree to the UXArmy's Terms and Privacy Policy"
                  name="tos"
                  onChange={(e, data) => setState({ ...state, tos: data.checked })}
                />
              </Form.Field>
              <Form.Button type="submit" primary disabled={!tos}>
                Next
              </Form.Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal size="mini" open={showModal}>
        <Modal.Content scrolling>
          <Image src={UXARMY_LOGO} size="small" centered />
          <Header as="h2" textAlign="center" className="mt-2">
            Welcome Buddy!
          </Header>
          <Container textAlign="center" className="p-2 mb-2" style={{ fontSize: '1.2rem' }}>
            {messages.greeting}
          </Container>
          <Button primary fluid content="Continue" onClick={() => setState({ ...state, showModal: false })} />
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

TreeStudy.propTypes = {
  verifyTest: PropTypes.func.isRequired,
  saveParticipant: PropTypes.func.isRequired,
  study: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  study: state.study
});

export default connect(
  mapStateToProps,
  { verifyTest, saveParticipant }
)(TreeStudy);
