import {
  TESTS_LOADED,
  SAVE_TEST_STATE,
  TEST_CREATED,
  TEST_UPDATED,
  TEST_SAVING,
  TEST_DELETED,
  UPDATE_TREE,
  TASK_SAVING
} from '../actions/types';

const initialState = { tests: [], test: null, loading: true, isSaving: false, isSavingTask: false };

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TESTS_LOADED:
      return { ...state, tests: payload, loading: false };
    case SAVE_TEST_STATE:
      return { ...state, test: payload };
    case TEST_CREATED:
    case TEST_UPDATED:
      return { ...state, test: payload, loading: false, isSaving: false };
    case TEST_SAVING:
      return { ...state, isSaving: payload };
    case TASK_SAVING:
      return { ...state, isSavingTask: payload };
    case TEST_DELETED:
      return { ...state, tests: state.tests.filter(test => test._id !== payload) };
    case UPDATE_TREE:
      return { ...state, test: { ...state.test, tree: payload } };
    default:
      return state;
  }
}
