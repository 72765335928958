// Test Status
export const TEST_STATUS_DRAFT = 0;
export const TEST_STATUS_ACTIVE = 1;
export const TEST_STATUS_INACTIVE = 2;
export const TEST_STATUS_EDIT = 3;
export const TEST_STATUS_COMPLETED = 4;
export const TEST_STATUS_DELETED = 5;

// Response
export const RESULT_SUCCESS = 1;
export const RESULT_FAILED = 0;
export const RESULT_DIRECT_SUCCESS = 1;
export const RESULT_INDIRECT_SUCCESS = 2;
export const RESULT_DIRECT_FAILURE = 3;
export const RESULT_INDIRECT_FAILURE = 4;
export const RESULT_DIRECT_SKIP = 5;
export const RESULT_INDIRECT_SKIP = 6;
