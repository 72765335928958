const countries = [
  { text: 'Afghanistan', value: 'af', key: 'af', flag: 'af' },
  { text: 'Aland Islands', value: 'ax', key: 'ax', flag: 'ax' },
  { text: 'Albania', value: 'al', key: 'al', flag: 'al' },
  { text: 'Algeria', value: 'dz', key: 'dz', flag: 'dz' },
  { text: 'American Samoa', value: 'as', key: 'as', flag: 'as' },
  { text: 'Andorra', value: 'ad', key: 'ad', flag: 'ad' },
  { text: 'Angola', value: 'ao', key: 'ao', flag: 'ao' },
  { text: 'Anguilla', value: 'ai', key: 'ai', flag: 'ai' },
  { text: 'Antigua', value: 'ag', key: 'ag', flag: 'ag' },
  { text: 'Argentina', value: 'ar', key: 'ar', flag: 'ar' },
  { text: 'Armenia', value: 'am', key: 'am', flag: 'am' },
  { text: 'Aruba', value: 'aw', key: 'aw', flag: 'aw' },
  { text: 'Australia', value: 'au', key: 'au', flag: 'au' },
  { text: 'Austria', value: 'at', key: 'at', flag: 'at' },
  { text: 'Azerbaijan', value: 'az', key: 'az', flag: 'az' },
  { text: 'Bahamas', value: 'bs', key: 'bs', flag: 'bs' },
  { text: 'Bahrain', value: 'bh', key: 'bh', flag: 'bh' },
  { text: 'Bangladesh', value: 'bd', key: 'bd', flag: 'bd' },
  { text: 'Barbados', value: 'bb', key: 'bb', flag: 'bb' },
  { text: 'Belarus', value: 'by', key: 'by', flag: 'by' },
  { text: 'Belgium', value: 'be', key: 'be', flag: 'be' },
  { text: 'Belize', value: 'bz', key: 'bz', flag: 'bz' },
  { text: 'Benin', value: 'bj', key: 'bj', flag: 'bj' },
  { text: 'Bermuda', value: 'bm', key: 'bm', flag: 'bm' },
  { text: 'Bhutan', value: 'bt', key: 'bt', flag: 'bt' },
  { text: 'Bolivia', value: 'bo', key: 'bo', flag: 'bo' },
  { text: 'Bosnia', value: 'ba', key: 'ba', flag: 'ba' },
  { text: 'Botswana', value: 'bw', key: 'bw', flag: 'bw' },
  { text: 'Bouvet Island', value: 'bv', key: 'bv', flag: 'bv' },
  { text: 'Brazil', value: 'br', key: 'br', flag: 'br' },
  { text: 'British Virgin Islands', value: 'vg', key: 'vg', flag: 'vg' },
  { text: 'Brunei', value: 'bn', key: 'bn', flag: 'bn' },
  { text: 'Bulgaria', value: 'bg', key: 'bg', flag: 'bg' },
  { text: 'Burkina Faso', value: 'bf', key: 'bf', flag: 'bf' },
  { text: 'Burma', value: 'mm', key: 'mm', flag: 'mm' },
  { text: 'Burundi', value: 'bi', key: 'bi', flag: 'bi' },
  { text: 'Caicos Islands', value: 'tc', key: 'tc', flag: 'tc' },
  { text: 'Cambodia', value: 'kh', key: 'kh', flag: 'kh' },
  { text: 'Cameroon', value: 'cm', key: 'cm', flag: 'cm' },
  { text: 'Canada', value: 'ca', key: 'ca', flag: 'ca' },
  { text: 'Cape Verde', value: 'cv', key: 'cv', flag: 'cv' },
  { text: 'Cayman Islands', value: 'ky', key: 'ky', flag: 'ky' },
  { text: 'Central African Republic', value: 'cf', key: 'cf', flag: 'cf' },
  { text: 'Chad', value: 'td', key: 'td', flag: 'td' },
  { text: 'Chile', value: 'cl', key: 'cl', flag: 'cl' },
  { text: 'China', value: 'cn', key: 'cn', flag: 'cn' },
  { text: 'Christmas Island', value: 'cx', key: 'cx', flag: 'cx' },
  { text: 'Cocos Islands', value: 'cc', key: 'cc', flag: 'cc' },
  { text: 'Colombia', value: 'co', key: 'co', flag: 'co' },
  { text: 'Comoros', value: 'km', key: 'km', flag: 'km' },
  { text: 'Congo', value: 'cd', key: 'cd', flag: 'cd' },
  { text: 'Congo Brazzaville', value: 'cg', key: 'cg', flag: 'cg' },
  { text: 'Cook Islands', value: 'ck', key: 'ck', flag: 'ck' },
  { text: 'Costa Rica', value: 'cr', key: 'cr', flag: 'cr' },
  { text: 'Cote Divoire', value: 'ci', key: 'ci', flag: 'ci' },
  { text: 'Croatia', value: 'hr', key: 'hr', flag: 'hr' },
  { text: 'Cuba', value: 'cu', key: 'cu', flag: 'cu' },
  { text: 'Cyprus', value: 'cy', key: 'cy', flag: 'cy' },
  { text: 'Czech Republic', value: 'cz', key: 'cz', flag: 'cz' },
  { text: 'Denmark', value: 'dk', key: 'dk', flag: 'dk' },
  { text: 'Djibouti', value: 'dj', key: 'dj', flag: 'dj' },
  { text: 'Dominica', value: 'dm', key: 'dm', flag: 'dm' },
  { text: 'Dominican Republic', value: 'do', key: 'do', flag: 'do' },
  { text: 'Ecuador', value: 'ec', key: 'ec', flag: 'ec' },
  { text: 'Egypt', value: 'eg', key: 'eg', flag: 'eg' },
  { text: 'El Salvador', value: 'sv', key: 'sv', flag: 'sv' },
  { text: 'Equatorial Guinea', value: 'gq', key: 'gq', flag: 'gq' },
  { text: 'Eritrea', value: 'er', key: 'er', flag: 'er' },
  { text: 'Estonia', value: 'ee', key: 'ee', flag: 'ee' },
  { text: 'Ethiopia', value: 'et', key: 'et', flag: 'et' },
  { text: 'Europeanunion', value: 'eu', key: 'eu', flag: 'eu' },
  { text: 'Falkland Islands', value: 'fk', key: 'fk', flag: 'fk' },
  { text: 'Faroe Islands', value: 'fo', key: 'fo', flag: 'fo' },
  { text: 'Fiji', value: 'fj', key: 'fj', flag: 'fj' },
  { text: 'Finland', value: 'fi', key: 'fi', flag: 'fi' },
  { text: 'France', value: 'fr', key: 'fr', flag: 'fr' },
  { text: 'French Guiana', value: 'gf', key: 'gf', flag: 'gf' },
  { text: 'French Polynesia', value: 'pf', key: 'pf', flag: 'pf' },
  { text: 'French Territories', value: 'tf', key: 'tf', flag: 'tf' },
  { text: 'Gabon', value: 'ga', key: 'ga', flag: 'ga' },
  { text: 'Gambia', value: 'gm', key: 'gm', flag: 'gm' },
  { text: 'Georgia', value: 'ge', key: 'ge', flag: 'ge' },
  { text: 'Germany', value: 'de', key: 'de', flag: 'de' },
  { text: 'Ghana', value: 'gh', key: 'gh', flag: 'gh' },
  { text: 'Gibraltar', value: 'gi', key: 'gi', flag: 'gi' },
  { text: 'Greece', value: 'gr', key: 'gr', flag: 'gr' },
  { text: 'Greenland', value: 'gl', key: 'gl', flag: 'gl' },
  { text: 'Grenada', value: 'gd', key: 'gd', flag: 'gd' },
  { text: 'Guadeloupe', value: 'gp', key: 'gp', flag: 'gp' },
  { text: 'Guam', value: 'gu', key: 'gu', flag: 'gu' },
  { text: 'Guatemala', value: 'gt', key: 'gt', flag: 'gt' },
  { text: 'Guinea', value: 'gn', key: 'gn', flag: 'gn' },
  { text: 'Guinea-Bissau', value: 'gw', key: 'gw', flag: 'gw' },
  { text: 'Guyana', value: 'gy', key: 'gy', flag: 'gy' },
  { text: 'Haiti', value: 'ht', key: 'ht', flag: 'ht' },
  { text: 'Heard Island', value: 'hm', key: 'hm', flag: 'hm' },
  { text: 'Honduras', value: 'hn', key: 'hn', flag: 'hn' },
  { text: 'Hong Kong', value: 'hk', key: 'hk', flag: 'hk' },
  { text: 'Hungary', value: 'hu', key: 'hu', flag: 'hu' },
  { text: 'Iceland', value: 'is', key: 'is', flag: 'is' },
  { text: 'India', value: 'in', key: 'in', flag: 'in' },
  { text: 'Indian Ocean Territory', value: 'io', key: 'io', flag: 'io' },
  { text: 'Indonesia', value: 'id', key: 'id', flag: 'id' },
  { text: 'Iran', value: 'ir', key: 'ir', flag: 'ir' },
  { text: 'Iraq', value: 'iq', key: 'iq', flag: 'iq' },
  { text: 'Ireland', value: 'ie', key: 'ie', flag: 'ie' },
  { text: 'Israel', value: 'il', key: 'il', flag: 'il' },
  { text: 'Italy', value: 'it', key: 'it', flag: 'it' },
  { text: 'Jamaica', value: 'jm', key: 'jm', flag: 'jm' },
  { text: 'Jan Mayen', value: 'sj', key: 'sj', flag: 'sj' },
  { text: 'Japan', value: 'jp', key: 'jp', flag: 'jp' },
  { text: 'Jordan', value: 'jo', key: 'jo', flag: 'jo' },
  { text: 'Kazakhstan', value: 'kz', key: 'kz', flag: 'kz' },
  { text: 'Kenya', value: 'ke', key: 'ke', flag: 'ke' },
  { text: 'Kiribati', value: 'ki', key: 'ki', flag: 'ki' },
  { text: 'Kuwait', value: 'kw', key: 'kw', flag: 'kw' },
  { text: 'Kyrgyzstan', value: 'kg', key: 'kg', flag: 'kg' },
  { text: 'Laos', value: 'la', key: 'la', flag: 'la' },
  { text: 'Latvia', value: 'lv', key: 'lv', flag: 'lv' },
  { text: 'Lebanon', value: 'lb', key: 'lb', flag: 'lb' },
  { text: 'Lesotho', value: 'ls', key: 'ls', flag: 'ls' },
  { text: 'Liberia', value: 'lr', key: 'lr', flag: 'lr' },
  { text: 'Libya', value: 'ly', key: 'ly', flag: 'ly' },
  { text: 'Liechtenstein', value: 'li', key: 'li', flag: 'li' },
  { text: 'Lithuania', value: 'lt', key: 'lt', flag: 'lt' },
  { text: 'Luxembourg', value: 'lu', key: 'lu', flag: 'lu' },
  { text: 'Macau', value: 'mo', key: 'mo', flag: 'mo' },
  { text: 'Macedonia', value: 'mk', key: 'mk', flag: 'mk' },
  { text: 'Madagascar', value: 'mg', key: 'mg', flag: 'mg' },
  { text: 'Malawi', value: 'mw', key: 'mw', flag: 'mw' },
  { text: 'Malaysia', value: 'my', key: 'my', flag: 'my' },
  { text: 'Maldives', value: 'mv', key: 'mv', flag: 'mv' },
  { text: 'Mali', value: 'ml', key: 'ml', flag: 'ml' },
  { text: 'Malta', value: 'mt', key: 'mt', flag: 'mt' },
  { text: 'Marshall Islands', value: 'mh', key: 'mh', flag: 'mh' },
  { text: 'Martinique', value: 'mq', key: 'mq', flag: 'mq' },
  { text: 'Mauritania', value: 'mr', key: 'mr', flag: 'mr' },
  { text: 'Mauritius', value: 'mu', key: 'mu', flag: 'mu' },
  { text: 'Mayotte', value: 'yt', key: 'yt', flag: 'yt' },
  { text: 'Mexico', value: 'mx', key: 'mx', flag: 'mx' },
  { text: 'Micronesia', value: 'fm', key: 'fm', flag: 'fm' },
  { text: 'Moldova', value: 'md', key: 'md', flag: 'md' },
  { text: 'Monaco', value: 'mc', key: 'mc', flag: 'mc' },
  { text: 'Mongolia', value: 'mn', key: 'mn', flag: 'mn' },
  { text: 'Montenegro', value: 'me', key: 'me', flag: 'me' },
  { text: 'Montserrat', value: 'ms', key: 'ms', flag: 'ms' },
  { text: 'Morocco', value: 'ma', key: 'ma', flag: 'ma' },
  { text: 'Mozambique', value: 'mz', key: 'mz', flag: 'mz' },
  { text: 'Namibia', value: 'na', key: 'na', flag: 'na' },
  { text: 'Nauru', value: 'nr', key: 'nr', flag: 'nr' },
  { text: 'Nepal', value: 'np', key: 'np', flag: 'np' },
  { text: 'Netherlands', value: 'nl', key: 'nl', flag: 'nl' },
  { text: 'Netherlandsantilles', value: 'an', key: 'an', flag: 'an' },
  { text: 'New Caledonia', value: 'nc', key: 'nc', flag: 'nc' },
  { text: 'New Guinea', value: 'pg', key: 'pg', flag: 'pg' },
  { text: 'New Zealand', value: 'nz', key: 'nz', flag: 'nz' },
  { text: 'Nicaragua', value: 'ni', key: 'ni', flag: 'ni' },
  { text: 'Niger', value: 'ne', key: 'ne', flag: 'ne' },
  { text: 'Nigeria', value: 'ng', key: 'ng', flag: 'ng' },
  { text: 'Niue', value: 'nu', key: 'nu', flag: 'nu' },
  { text: 'Norfolk Island', value: 'nf', key: 'nf', flag: 'nf' },
  { text: 'North Korea', value: 'kp', key: 'kp', flag: 'kp' },
  { text: 'Northern Mariana Islands', value: 'mp', key: 'mp', flag: 'mp' },
  { text: 'Norway', value: 'no', key: 'no', flag: 'no' },
  { text: 'Oman', value: 'om', key: 'om', flag: 'om' },
  { text: 'Pakistan', value: 'pk', key: 'pk', flag: 'pk' },
  { text: 'Palau', value: 'pw', key: 'pw', flag: 'pw' },
  { text: 'Palestine', value: 'ps', key: 'ps', flag: 'ps' },
  { text: 'Panama', value: 'pa', key: 'pa', flag: 'pa' },
  { text: 'Paraguay', value: 'py', key: 'py', flag: 'py' },
  { text: 'Peru', value: 'pe', key: 'pe', flag: 'pe' },
  { text: 'Philippines', value: 'ph', key: 'ph', flag: 'ph' },
  { text: 'Pitcairn Islands', value: 'pn', key: 'pn', flag: 'pn' },
  { text: 'Poland', value: 'pl', key: 'pl', flag: 'pl' },
  { text: 'Portugal', value: 'pt', key: 'pt', flag: 'pt' },
  { text: 'Puerto Rico', value: 'pr', key: 'pr', flag: 'pr' },
  { text: 'Qatar', value: 'qa', key: 'qa', flag: 'qa' },
  { text: 'Reunion', value: 're', key: 're', flag: 're' },
  { text: 'Romania', value: 'ro', key: 'ro', flag: 'ro' },
  { text: 'Russia', value: 'ru', key: 'ru', flag: 'ru' },
  { text: 'Rwanda', value: 'rw', key: 'rw', flag: 'rw' },
  { text: 'Saint Helena', value: 'sh', key: 'sh', flag: 'sh' },
  { text: 'Saint Kitts and Nevis', value: 'kn', key: 'kn', flag: 'kn' },
  { text: 'Saint Lucia', value: 'lc', key: 'lc', flag: 'lc' },
  { text: 'Saint Pierre', value: 'pm', key: 'pm', flag: 'pm' },
  { text: 'Saint Vincent', value: 'vc', key: 'vc', flag: 'vc' },
  { text: 'Samoa', value: 'ws', key: 'ws', flag: 'ws' },
  { text: 'San Marino', value: 'sm', key: 'sm', flag: 'sm' },
  { text: 'Sandwich Islands', value: 'gs', key: 'gs', flag: 'gs' },
  { text: 'Sao Tome', value: 'st', key: 'st', flag: 'st' },
  { text: 'Saudi Arabia', value: 'sa', key: 'sa', flag: 'sa' },
  { text: 'Scotland', value: 'gb sct', key: 'gb sct', flag: 'gb sct' },
  { text: 'Senegal', value: 'sn', key: 'sn', flag: 'sn' },
  { text: 'Serbia', value: 'cs', key: 'cs', flag: 'cs' },
  { text: 'Serbia', value: 'rs', key: 'rs', flag: 'rs' },
  { text: 'Seychelles', value: 'sc', key: 'sc', flag: 'sc' },
  { text: 'Sierra Leone', value: 'sl', key: 'sl', flag: 'sl' },
  { text: 'Singapore', value: 'sg', key: 'sg', flag: 'sg' },
  { text: 'Slovakia', value: 'sk', key: 'sk', flag: 'sk' },
  { text: 'Slovenia', value: 'si', key: 'si', flag: 'si' },
  { text: 'Solomon Islands', value: 'sb', key: 'sb', flag: 'sb' },
  { text: 'Somalia', value: 'so', key: 'so', flag: 'so' },
  { text: 'South Africa', value: 'za', key: 'za', flag: 'za' },
  { text: 'South Korea', value: 'kr', key: 'kr', flag: 'kr' },
  { text: 'Spain', value: 'es', key: 'es', flag: 'es' },
  { text: 'Sri Lanka', value: 'lk', key: 'lk', flag: 'lk' },
  { text: 'Sudan', value: 'sd', key: 'sd', flag: 'sd' },
  { text: 'Suritext', value: 'sr', key: 'sr', flag: 'sr' },
  { text: 'Swaziland', value: 'sz', key: 'sz', flag: 'sz' },
  { text: 'Sweden', value: 'se', key: 'se', flag: 'se' },
  { text: 'Switzerland', value: 'ch', key: 'ch', flag: 'ch' },
  { text: 'Syria', value: 'sy', key: 'sy', flag: 'sy' },
  { text: 'Taiwan', value: 'tw', key: 'tw', flag: 'tw' },
  { text: 'Tajikistan', value: 'tj', key: 'tj', flag: 'tj' },
  { text: 'Tanzania', value: 'tz', key: 'tz', flag: 'tz' },
  { text: 'Thailand', value: 'th', key: 'th', flag: 'th' },
  { text: 'Timorleste', value: 'tl', key: 'tl', flag: 'tl' },
  { text: 'Togo', value: 'tg', key: 'tg', flag: 'tg' },
  { text: 'Tokelau', value: 'tk', key: 'tk', flag: 'tk' },
  { text: 'Tonga', value: 'to', key: 'to', flag: 'to' },
  { text: 'Trinidad', value: 'tt', key: 'tt', flag: 'tt' },
  { text: 'Tunisia', value: 'tn', key: 'tn', flag: 'tn' },
  { text: 'Turkey', value: 'tr', key: 'tr', flag: 'tr' },
  { text: 'Turkmenistan', value: 'tm', key: 'tm', flag: 'tm' },
  { text: 'Tuvalu', value: 'tv', key: 'tv', flag: 'tv' },
  { text: 'U.A.E.', value: 'ae', key: 'ae', flag: 'ae' },
  { text: 'Uganda', value: 'ug', key: 'ug', flag: 'ug' },
  { text: 'Ukraine', value: 'ua', key: 'ua', flag: 'ua' },
  { text: 'United Kingdom', value: 'gb', alias: 'uk', key: 'uk', flag: 'uk' },
  { text: 'United States', value: 'us', key: 'us', flag: 'us' },
  { text: 'Uruguay', value: 'uy', key: 'uy', flag: 'uy' },
  { text: 'US Minor Islands', value: 'um', key: 'um', flag: 'um' },
  { text: 'US Virgin Islands', value: 'vi', key: 'vi', flag: 'vi' },
  { text: 'Uzbekistan', value: 'uz', key: 'uz', flag: 'uz' },
  { text: 'Vanuatu', value: 'vu', key: 'vu', flag: 'vu' },
  { text: 'Vatican City', value: 'va', key: 'va', flag: 'va' },
  { text: 'Venezuela', value: 've', key: 've', flag: 've' },
  { text: 'Vietnam', value: 'vn', key: 'vn', flag: 'vn' },
  { text: 'Wallis and Futuna', value: 'wf', key: 'wf', flag: 'wf' },
  { text: 'Western Sahara', value: 'eh', key: 'eh', flag: 'eh' },
  { text: 'Yemen', value: 'ye', key: 'ye', flag: 'ye' },
  { text: 'Zambia', value: 'zm', key: 'zm', flag: 'zm' },
  { text: 'Zimbabwe', value: 'zw', key: 'zw', flag: 'zw' }
];

export default countries;
