import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';
import Navber from '../layouts/Navbar';

// import axios from 'axios';
import { Form, Header, Grid } from 'semantic-ui-react';

const Register = ({ register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    acceptTos: false,
    showError: false
  });

  const { firstname, lastname, email, password, acceptTos, showError } = formData;

  const onChange = d => setFormData({ ...formData, [d.name]: d.value });

  const onSubmit = async e => {
    e.preventDefault();
    setFormData({ ...formData, showError: true });

    if (firstname && email && password) register(firstname, lastname, email, password);
  };

  // Redirect if loggedin
  if (isAuthenticated) return <Redirect to="/" />;

  return (
    <Fragment>
      <Navber />
      <Grid className="login-sign-form bg-white" centered textAlign="center" verticalAlign="middle">
        <Grid.Column width={5}>
          <Header as="h2" textAlign="center">
            Register an account now!
          </Header>
          <Header as="h4" textAlign="center">
            Sign up an account and together we make the web a better place.
          </Header>
          <Form className="fluid" onSubmit={e => onSubmit(e)}>
            <Form.Group widths="equal">
              <Form.Input
                label="First Name"
                placeholder="First Name"
                name="firstname"
                value={firstname}
                onChange={(e, d) => onChange(d)}
                error={showError && firstname.length === 0}
              />
              <Form.Input
                label="Last Name"
                placeholder="Last Name"
                name="lastname"
                value={lastname}
                onChange={(e, d) => onChange(d)}
              />
            </Form.Group>
            <Form.Input
              label="Email"
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={(e, d) => onChange(d)}
              error={showError && email.length === 0}
            />
            <Form.Input
              label="Password"
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e, d) => onChange(d)}
              error={showError && password.length === 0}
            />
            <Form.Checkbox
              name="acceptTos"
              checked={acceptTos}
              onChange={(e, d) => setFormData({ ...formData, acceptTos: d.checked })}
              label={
                <label>
                  by sign up, you agree to our <Link to="/tos">Terms</Link> & <Link to="/policy">Privacy Policy</Link>
                </label>
              }
            />
            <Form.Button type="submit" primary fluid disabled={!acceptTos}>
              Sign Up
            </Form.Button>
          </Form>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { register }
)(Register);
