import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Image, Menu, Icon, Grid, Header, Table, Popup, Label } from 'semantic-ui-react';
import { FIRST_CLICK } from '../../utills/icons';

const FirstClickDestinations = ({ report: { response, test } }) => {
  // console.log(response, test);
  const treeData = test.tree.treeData;
  const tasks = test.tasks;

  let treeLength = 0;
  const loopTreeNodes = data => {
    return data.map(item => {
      treeLength++;
      const isLeaf = item.children.length > 0 ? false : true;
      if (item.children) {
        return (
          <li key={item.id} className={isLeaf ? 'leaf-el' : 'parent-el'}>
            <p className="node-text">{item.name}</p>
            <ul>{loopTreeNodes(item.children)}</ul>
          </li>
        );
      }
      return (
        <li key={item.id} className={isLeaf ? 'leaf-el' : 'parent-el'}>
          <p className="node-text">{item.name}</p>
        </li>
      );
    });
  };
  const treeNodes = loopTreeNodes(treeData);

  const createFakeNode = key => {
    const randomNumber1 = Math.floor(Math.random() * 10) + 0;
    const randomNumber2 = Math.floor(Math.random() * 10) + 0;
    return (
      <Table.Row textAlign="center" key={key}>
        <Table.Cell className={randomNumber1 === 5 ? 'most-selected p-1' : 'p-1'}>{randomNumber1 > 2 ? randomNumber1 : ''}</Table.Cell>
        <Table.Cell className={randomNumber2 === 4 ? 'most-success p-1' : randomNumber2 === 3 ? 'most-failed p-1' : 'p-1'}>
          {randomNumber2 > 2 ? randomNumber2 : ''}
        </Table.Cell>
      </Table.Row>
    );
  };

  const fakeNodes = () => {
    const fakeNodes = [];
    for (let i = 0; i < treeLength; i++) fakeNodes.push(createFakeNode(`fake${i}`));
    return fakeNodes;
  };

  return (
    <Container>
      <Menu fluid className="space-between">
        <Menu.Item>
          <Image
            src={FIRST_CLICK}
            size="mini"
            verticalAlign="middle"
            style={{ maxHeight: '14px', width: 'auto', marginRight: '0.5rem' }}
          />{' '}
          1<sup>st</sup> click
        </Menu.Item>
        <Menu.Item style={{ borderRight: '1px solid #dededf' }}>
          <Icon name="square" className="direct-skip" /> Most selected 1<sup>st</sup> click
        </Menu.Item>
        <Menu.Item name="Answer destination" icon="crosshairs" />
        <Menu.Item>
          <Icon name="square" className="direct-success" /> Correct destination
        </Menu.Item>
        <Menu.Item>
          <Icon name="square" className="direct-failure" /> Most selected wrong destination
        </Menu.Item>
      </Menu>

      <Grid>
        <Grid.Row>
          <Grid.Column width="6" className="tree-nodes">
            <Header as="h3">
              <Icon name="align right" flipped="vertically" size="mini" />
              <Header.Content> Tree Structure</Header.Content>
            </Header>
            <ul>{treeNodes}</ul>
          </Grid.Column>
          <Grid.Column width="10">
            <Container className="task-list">
              {tasks.map((task, index) => (
                <Table key={index} celled>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell textAlign="center" colSpan="2" className="p-1">
                        <Popup
                          content={task.description}
                          trigger={<Label basic className="border-none">{`Task ${index + 1}`}</Label>}
                          position="top center"
                          inverted
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row textAlign="center">
                      <Table.Cell className="p-1">
                        <Image src={FIRST_CLICK} size="mini" className="auto-width m-0" inline verticalAlign="top" />
                      </Table.Cell>
                      <Table.Cell className="p-1">
                        <Icon name="crosshairs" className="m-0" />
                      </Table.Cell>
                    </Table.Row>
                    {fakeNodes()}
                  </Table.Body>
                </Table>
              ))}
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

FirstClickDestinations.propTypes = {
  report: PropTypes.object
};

const mapStateToProps = state => ({
  report: state.report
});

export default connect(mapStateToProps)(FirstClickDestinations);
