import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import test from './test';
import survey from './survey';
import report from './report';
import study from './study';

const appReducer = combineReducers({
  alert,
  auth,
  test,
  survey,
  report,
  study
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
