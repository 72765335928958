import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form, Header, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import Navber from '../layouts/Navbar';

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: true,
    showError: false
  });

  const { email, password, remember, showError } = formData;

  const onSubmit = async e => {
    e.preventDefault();
    setFormData({ ...formData, showError: true });

    if (email && password) login(email, password, remember);
  };

  // Redirect if loggedin
  if (isAuthenticated) return <Redirect to="/" />;

  return (
    <Fragment>
      <Navber />
      <Grid className="login-sign-form bg-white" centered textAlign="center" verticalAlign="middle">
        <Grid.Column width={5}>
          <Header as="h2" textAlign="center">
            Welcome Back
          </Header>
          <Header as="h4" textAlign="center">
            Sign in to start your tree testing journey now!
          </Header>
          <Form className="fluid" onSubmit={e => onSubmit(e)}>
            <Form.Input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={(e, d) => setFormData({ ...formData, email: d.value })}
              error={showError && email.length === 0}
            />
            <Form.Input
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e, d) => setFormData({ ...formData, password: d.value })}
              error={showError && password.length === 0}
            />
            <Form.Group>
              <Form.Checkbox
                label="Remember me next time"
                name="remember"
                width="10"
                checked={remember}
                onChange={(e, d) => setFormData({ ...formData, remember: d.checked })}
              />
              <Link to="forgot-password" width="6">
                Forgot your password?
              </Link>
            </Form.Group>
            <Form.Button type="submit" primary fluid>
              Login
            </Form.Button>
          </Form>
          <Header as="h5" textAlign="center">
            Don't have an account?{' '}
            <a href="https://www.uxarmy.com/contact-ux-army" target="_blank" rel="noopener noreferrer">
              Contact Us Now!
            </a>
            {/* <Link to="/sign-up">Sign Up Now!</Link> */}
          </Header>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { login }
)(Login);
