import axios from 'axios';

const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token;
  } else {
    delete axios.defaults.headers.common['x-auth-token'];
  }

  // BASE_URL of Server
  axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
};

export default setAuthToken;
