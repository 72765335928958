import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Header, Container, Button, Image } from 'semantic-ui-react';
import Navbar from './Navbar';
import { ERROR_404_ICON } from '../../utills/icons';

const NotFound = () => (
  <Fragment>
    <Navbar />
    <Container textAlign="center" className="pt-4" style={{ marginTop: '5rem' }}>
      <Image src={ERROR_404_ICON} centered />
      <Header as="h2" style={{ color: '#7D9596' }}>
        Whoops! Page not found…
      </Header>
      <Button as={Link} to="/" primary>
        Return to Home Page
      </Button>
    </Container>
  </Fragment>
);
export default NotFound;
