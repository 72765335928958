import axios from 'axios';
import { REPORT_LOAD } from './types';
// import { setAlert } from './alert';

// Get Test
export const getTestDetails = testId => async dispatch => {
  try {
    const res = await axios.get(`/api/response/test/${testId}`);
    dispatch({ type: REPORT_LOAD, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};
