import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const UXLoader = () => (
  <Dimmer active inverted>
    <Loader inverted>Please wait...</Loader>
  </Dimmer>
);

export default UXLoader;
