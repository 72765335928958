// Import Icons
import uxarmyLogo from '../assets/images/uxarmy-logo.svg';
import emptyTest from '../assets/images/test-empty-2x.png';
import testSetup from '../assets/images/test-setup.svg';
import testDetails from '../assets/images/test-details.svg';
import messages from '../assets/images/messages.svg';
import recruit from '../assets/images/recruit.svg';
import questionnaire from '../assets/images/questionnaire.svg';
import importIcon from '../assets/images/import.svg';
import closeIcon from '../assets/images/close.svg';
import activeTestIcon from '../assets/images/status/on-going.svg';
import inactiveTestIcon from '../assets/images/status/inactive.svg';
import draftTestIcon from '../assets/images/status/draft.svg';
import completedTestIcon from '../assets/images/status/completed.svg';
import activeTestLabel from '../assets/images/status/label/active.png';
import inactiveTestLabel from '../assets/images/status/label/inactive.png';
import draftTestLabel from '../assets/images/status/label/draft.png';
import completedTestLabel from '../assets/images/status/label/completed.png';
import reportOverviewIcon from '../assets/images/overview.svg';
import reportAnalysisIcon from '../assets/images/analysis.svg';
import genderMale from '../assets/images/male@2x.png';
import genderFemale from '../assets/images/female@2x.png';
import testcomplete from '../assets/images/test-complete@2x.png';
import defineAnswer from '../assets/images/define-answer.png';
import selectAnswer from '../assets/images/select-answer.png';
import cautionIcon from '../assets/images/caution.svg';
import confirmationIcon from '../assets/images/confirmation.svg';
import instructionsIcon from '../assets/images/instructions.svg';
import error404 from '../assets/images/error404.svg';
import ohSnapIcon from '../assets/images/oh-snap.svg';
import firstClick from '../assets/images/1st-click.svg';

// Export Icons
export const UXARMY_LOGO = uxarmyLogo;
export const EMPTY_TEST_ICON = emptyTest;
export const TEST_SETUP_ICON = testSetup;
export const TEST_DTAILS_ICON = testDetails;
export const TEST_MESSAGES_ICON = messages;
export const TEST_RECRUIT_ICON = recruit;
export const TEST_QUESTIONNAIRE_ICON = questionnaire;
export const TREE_IMPORT_ICON = importIcon;
export const CLOSE_ICON = closeIcon;
export const ACTIVE_TEST_ICON = activeTestIcon;
export const INACTIVE_TEST_ICON = inactiveTestIcon;
export const DRAFT_TEST_ICON = draftTestIcon;
export const COMPLETED_TEST_ICON = completedTestIcon;
export const ACTIVE_TEST_LABEL = activeTestLabel;
export const INACTIVE_TEST_LABEL = inactiveTestLabel;
export const DRAFT_TEST_LABEL = draftTestLabel;
export const COMPLETED_TEST_LABEL = completedTestLabel;
export const OVERVIEW_ICON = reportOverviewIcon;
export const ANALYSIS_ICON = reportAnalysisIcon;
export const GENDER_MALE = genderMale;
export const GENDER_FEMALE = genderFemale;
export const TEST_COMPLETE = testcomplete;
export const DEFINE_ANSWER = defineAnswer;
export const SELECT_ANSWER = selectAnswer;
export const CAUTION_ICON = cautionIcon;
export const CONFIRMATION_ICON = confirmationIcon;
export const INSTRUCTIONS_ICON = instructionsIcon;
export const ERROR_404_ICON = error404;
export const OH_SNAP_ICON = ohSnapIcon;
export const FIRST_CLICK = firstClick;
