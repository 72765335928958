import { uniqueID } from './common';

const findNode = (node, nodeId, callback) => {
  if (node.id === nodeId) {
    callback(node);
    return;
  }
  if (node.children) {
    node.children.forEach(element => {
      findNode(element, nodeId, callback);
    });
  }
};

// Get Node Path
export const getNodePath = (treeData, nodeId, getPathString = false) => {
  const rootNode = treeData[0];
  const getNodeItem = node => (item = node);

  let item;
  findNode(rootNode, nodeId, getNodeItem);

  let path = [];
  while (item && item.parentId !== null) {
    path.unshift({ id: item.id, name: item.name });

    findNode(rootNode, item.parentId, getNodeItem);
  }
  item && path.unshift({ id: item.id, name: item.name });

  return getPathString ? path.map(node => node.name).join(' > ') : path;
};

// Tree Animation
export const treeMotion = {
  motionName: 'node-motion',
  motionAppear: false,
  onEnterActive: node => ({ height: node.scrollHeight }),
  onLeaveStart: node => ({ height: node.offsetHeight })
};

// Default Tree
export const getDefaultTree = () => {
  return { treeData: [{ id: uniqueID(), name: 'Home', isLeaf: true, parentId: null, children: [] }] };
};

// filter right answers becoz user may have update the tree and current node may not be the leaf node.
export const filterAnswers = (treeData, answers) => {
  answers = answers.filter(nodeId => {
    let item;
    findNode(treeData[0], nodeId, node => (item = node));
    if (item && item.children && item.children.length === 0) return nodeId;
    return '';
  });
  return answers;
};
