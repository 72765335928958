import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const DonutChart = ({ value, color }) => {
  value = parseInt(value);
  const data = [{ value }, { value: 100 - value }];
  const colors = [color, '#EBEBEB'];

  return (
    <PieChart width={120} height={120}>
      <text x={70} y={70} fill="black" textAnchor="middle">
        {value}%
      </text>
      <Pie data={data} cx={60} cy={60} innerRadius={40} outerRadius={55} fill="#8884d8" dataKey="value">
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};
export default DonutChart;
