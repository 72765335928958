import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Header, Container, Grid, Image, Button, Table, Icon, Popup, Checkbox, Pagination } from 'semantic-ui-react';
import { EMPTY_TEST_ICON } from '../../utills/icons';
import { getAllTests, deleteTest, duplicateTest } from '../../actions/test';
import { getTestStatuslabelIcon, getTestStatuslabelText } from '../../utills/testFunctions';
import { TEST_STATUS_DRAFT } from '../../actions/constants';
import UXLoader from '../layouts/UXLoader';
import Moment from 'react-moment';

const Tests = ({ getAllTests, test: { loading, tests }, deleteTest, duplicateTest }) => {
  // Get All Tests
  useEffect(() => {
    getAllTests();
  }, [getAllTests]);

  // Tests Filter State
  const [filter, updateFilter] = useState({ currentPage: 1, pageLimit: 10, startIndex: 0, endIndex: 10 });
  let { currentPage, pageLimit, startIndex, endIndex } = filter;

  // Table Pagination
  const onChangePage = data => {
    const { activePage } = data;
    if (activePage !== currentPage) {
      if (activePage === 1) {
        startIndex = 0;
        endIndex = 10;
      } else {
        endIndex = activePage * pageLimit - 1;
        startIndex = endIndex - pageLimit;
      }

      updateFilter({ ...filter, currentPage: activePage, startIndex, endIndex });
    }
  };

  if (loading) return <UXLoader />;

  return (
    <Container>
      <Grid className="m-0 pb-2 pt-2">
        <Grid.Row>
          <Grid.Column className="tests-heading p-0" verticalAlign="middle">
            <Header as="h2" className="m-0">
              All Tests
              <Header.Subheader>Total Tests({tests.length})</Header.Subheader>
            </Header>
            <Button primary as={Link} to="/tests/new" floated="right">
              + Create Test
            </Button>
          </Grid.Column>
        </Grid.Row>
        {tests.length === 0 ? (
          <Grid.Row className="tests-body" centered verticalAlign="middle">
            <Grid.Column width={5} textAlign="center">
              <Image src={EMPTY_TEST_ICON} size="small" centered />
              <Header as="h2">
                No Tests has been created
                <br />
                Lets create it now!
              </Header>
              <Button primary as={Link} to="/tests/new" fluid>
                + Create Test
              </Button>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Table className="p-0 m-0" selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">
                  <Popup content="Select All" trigger={<Checkbox />} position="top center" inverted />
                </Table.HeaderCell>
                <Table.HeaderCell>Test Name</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Date Created</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Last Modified</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {tests.slice(startIndex, endIndex).map((test, index) => (
                <Table.Row key={index}>
                  <Table.Cell textAlign="center">
                    <Checkbox />
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/tests/${test._id}`}>{test.name}</Link>
                  </Table.Cell>
                  <Table.Cell className="status-icon pointer">
                    <Popup
                      content={getTestStatuslabelText(test.status)}
                      trigger={<Image src={getTestStatuslabelIcon(test.status)} size="mini" centered />}
                      position="top center"
                      inverted
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Moment format="DD MMMM YYYY">{test.createdAt}</Moment>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Moment format="DD MMMM YYYY">{test.updatedAt}</Moment> | by{' '}
                    {test.creator.name.first + ' ' + (test.creator.name.last ? test.creator.name.last : '')}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Popup
                      content="Report"
                      trigger={
                        <Link to={`/tests/report/${test._id}`} className={test.status === TEST_STATUS_DRAFT ? 'disabled' : ''}>
                          <Icon name="pie graph" />
                        </Link>
                      }
                      position="top center"
                      inverted
                    />
                    <Popup
                      content="Edit"
                      trigger={
                        <Link to={`/tests/${test._id}/edit`} className={!test.isEditable ? 'disabled' : ''}>
                          <Icon name="edit outline" />
                        </Link>
                      }
                      position="top center"
                      inverted
                    />
                    <Popup
                      content="Preview"
                      trigger={
                        <Link to={`/tests/${test._id}/preview`} className={test.status === TEST_STATUS_DRAFT ? 'disabled' : ''}>
                          <Icon name="eye" />
                        </Link>
                      }
                      position="top center"
                      inverted
                    />
                    <Popup
                      content="Duplicate"
                      trigger={
                        <Icon
                          name="copy outline"
                          onClick={() => duplicateTest(test._id)}
                          link
                          disabled={test.status === TEST_STATUS_DRAFT || true}
                        />
                      }
                      position="top center"
                      inverted
                    />
                    <Popup
                      content="Delete"
                      trigger={<Icon name="trash alternate outline" onClick={() => deleteTest(test._id)} link />}
                      position="top center"
                      inverted
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="6" textAlign="right">
                  <Pagination
                    totalPages={Math.ceil(tests.length / pageLimit)}
                    activePage={currentPage}
                    onPageChange={(e, d) => onChangePage(d)}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </Grid>
    </Container>
  );
};

Tests.propTypes = {
  getAllTests: PropTypes.func.isRequired,
  deleteTest: PropTypes.func.isRequired,
  duplicateTest: PropTypes.func.isRequired,
  test: PropTypes.object.isRequired
};

const mapStateToProps = state => ({ test: state.test });

export default connect(
  mapStateToProps,
  { getAllTests, deleteTest, duplicateTest }
)(Tests);
