import { SET_ALERT, REMOVE_ALERT } from './types';
import { uniqueID } from '../utills/common';

export const setAlert = (msg, alertType = '', timeout = 3500) => dispatch => {
  const id = uniqueID();
  dispatch({ type: SET_ALERT, payload: { msg, alertType, id } });

  // Remove Alert
  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};

export const removeAlert = alertId => dispatch => dispatch({ type: REMOVE_ALERT, payload: alertId });
