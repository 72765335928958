import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Table, Icon, Popup, Checkbox } from 'semantic-ui-react';
import { getParticipantEmail, calculateTimeTaken } from '../../utills/reportFunctions';
import { RESULT_DIRECT_SUCCESS, RESULT_INDIRECT_SUCCESS } from '../../actions/constants';

const AnalysisPath = ({ report: { response, test } }) => {
  const totalTasks = test ? test.tasks.length : 0;

  const getTaskSuccessfullRate = item => {
    if (!item.taskAnswers || item.taskAnswers.length === 0) return 0;

    const successTasks = item.taskAnswers.filter(
      taskAns => taskAns.result === RESULT_DIRECT_SUCCESS || taskAns.result === RESULT_INDIRECT_SUCCESS
    );

    return ((successTasks.length / totalTasks) * 100).toFixed(0);
  };

  return (
    <Container>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              <Popup content="Select All" trigger={<Checkbox />} position="top center" inverted />
            </Table.HeaderCell>
            <Table.HeaderCell>Participant</Table.HeaderCell>
            <Table.HeaderCell>Time Taken</Table.HeaderCell>
            <Table.HeaderCell>Task Completion (%)</Table.HeaderCell>
            <Table.HeaderCell>Task Skipped (%)</Table.HeaderCell>
            <Table.HeaderCell>Task Successful (%)</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {response.length !== 0 ? (
            response.map((item, index) => (
              <Table.Row key={index}>
                <Table.Cell textAlign="center">
                  <Checkbox />
                </Table.Cell>
                <Table.Cell>{getParticipantEmail(item.participant)}</Table.Cell>
                <Table.Cell>{calculateTimeTaken(item.startTime, item.endTime)}</Table.Cell>
                <Table.Cell>{parseInt((item.taskAnswers.length / totalTasks) * 100)}%</Table.Cell>
                <Table.Cell>0%</Table.Cell>
                <Table.Cell>{getTaskSuccessfullRate(item)}%</Table.Cell>
                <Table.Cell>
                  <Checkbox toggle disabled />{' '}
                  <Icon name="trash alternate outline" link className="ml-1" style={{ verticalAlign: 'super' }} disabled />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3">
                <Icon name="info circle" color="green" /> No Participants available!
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Container>
  );
};

AnalysisPath.propTypes = {
  report: PropTypes.object
};

const mapStateToProps = state => ({
  report: state.report
});

export default connect(mapStateToProps)(AnalysisPath);
