import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { Menu, Container, Image, Dropdown } from 'semantic-ui-react';
import { UXARMY_LOGO } from '../../utills/icons';

const Navbar = ({ logout, auth: { isAuthenticated, loading, user } }) => {
  return (
    <Menu borderless className="navbar" fixed="top">
      <Container>
        <Menu.Item as={Link} to="/" className="m-0 pl-0">
          <Image src={UXARMY_LOGO} size="small" />
        </Menu.Item>
        {isAuthenticated && !loading && (
          <Menu.Item
            as={Link}
            to="/"
            name="tests"
            active
            className="tests-btn"
          >Tree Testing<sup style={{color:"blue", fontStyle: "italic"}}>&nbsp;&nbsp;BETA</sup></Menu.Item>
        )}
        {isAuthenticated && user ? (
          <Menu.Menu position="right">
            <Dropdown item text={user.name.first}>
              <Dropdown.Menu>
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        ) : (
          <Menu.Menu position="right">
            <Menu.Item as={Link} to="/login" name="login" />
            {/* <Menu.Item as={Link} to="/sign-up" name="sign up" className="signup-btn" /> */}
            <Menu.Item>
              <a
                href="https://www.uxarmy.com/contact-ux-army"
                target="_blank"
                rel="noopener noreferrer"
                className="signup-btn"
              >
                Sign up
              </a>
            </Menu.Item>
          </Menu.Menu>
        )}
      </Container>
    </Menu>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logout }
)(Navbar);
