import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Message, Icon } from 'semantic-ui-react';
import { removeAlert } from '../../actions/alert';
import '../../assets/css/alert.css';

const Alert = ({ alerts, removeAlert }) => {
  const getAlertIcon = alertType => {
    switch (alertType) {
      case 'success':
        return 'check circle';
      case 'error':
        return 'info circle';
      default:
        return '';
    }
  };

  return (
    <div className="alert-container">
      {alerts &&
        alerts.length > 0 &&
        alerts.map(alert => (
          <Message key={alert.id} className={alert.alertType}>
            {alert.alertType !== '' && <Icon name={getAlertIcon(alert.alertType)} />}
            <span className="mr-2">{alert.msg}</span>
            <Icon name="close" onClick={() => removeAlert(alert.id)} link />
          </Message>
        ))}
    </div>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
  removeAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(
  mapStateToProps,
  { removeAlert }
)(Alert);
