import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Container, Grid, Segment, Label, List, Popup, Icon } from 'semantic-ui-react';
import { RESULT_FAILED, RESULT_SUCCESS, RESULT_DIRECT_SUCCESS, RESULT_INDIRECT_SUCCESS } from '../../actions/constants';
import { calculateTimeTaken, getDurationInMMSS, getResultType } from '../../utills/reportFunctions';
import PieChart from '../graphs/pie';
import DonutChart from '../graphs/donut';
import HorizontalBarChart from '../graphs/horizontalBar';
import UXLoader from '../layouts/UXLoader';
import '../../assets/css/report.css';

const Overview = ({ report: { response, test } }) => {
  const totalTasks = test ? test.tasks.length : 0;
  const totalParticipants = response ? response.length : 0;

  // Calculate Success/Fail Count
  let successCount = 0;
  let failCount = 0;

  // Time Taken Array
  const timeTakenValues = [];

  const barChartData = [];

  // Directness/Success
  let totalResults = 0;
  let successRate = 0;
  let directnessRate = 0;

  // Traverse the Response
  response.map(res => {
    // Success/Fail Count
    if (res.status === RESULT_FAILED) failCount++;
    if (res.status === RESULT_SUCCESS) successCount++;

    // Put Test Duration into array for further processing
    timeTakenValues.push(calculateTimeTaken(res.startTime, res.endTime, true)); // true: get duration in seconds

    // Calculate Success Rate & Directness
    const taskAnswers = res.taskAnswers;

    totalResults += taskAnswers.length; // Total Results

    for (let i = 0; i < taskAnswers.length; i++) {
      const result = taskAnswers[i].result;

      // SuccessRate/DirectNess
      if (result === RESULT_DIRECT_SUCCESS || result === RESULT_INDIRECT_SUCCESS) successRate++;
      if (result === RESULT_DIRECT_SUCCESS) directnessRate++;

      // BarData
      let resultType = getResultType(result);
      if (!barChartData[i]) {
        barChartData[i] = { name: `Task ${i + 1}` };
      }

      let taskResult = { ...barChartData[i] };
      let currentResult = taskResult[resultType] ? taskResult[resultType] : 0;
      taskResult[resultType] = ++currentResult;
      barChartData[i] = taskResult;
    }

    return '';
  });

  // Convert successRate/Directness into percentage
  if (totalResults) {
    successRate = ((successRate / totalResults) * 100).toFixed(0);
    directnessRate = ((directnessRate / totalResults) * 100).toFixed(0);
  }

  // Returns AVG value from Array
  const getAvgFromArray = arr => arr.reduce((a, b) => a + b, 0) / arr.length;

  // Pie Chart Data
  const pieChartData = [{ name: 'Abandoned', value: failCount }, { name: 'Completed', value: successCount }];
  const pieChartColors = ['#FA3666', '#2CBFCA'];

  if (!test) {
    return <UXLoader />;
  }

  return (
    <Container>
      <Grid className="p-2">
        <Grid.Row>
          <Grid.Column width="16">
            <Header as="h2" className="report-heading mb-1">
              Participants{' '}
              <span className="font-weight-normal ml-2">
                | <small> Total Participants ({totalParticipants})</small>
              </span>
            </Header>
          </Grid.Column>
          <Grid.Column width="8" className="pr-0">
            <Segment>
              <Header as="h4">Completion Rate</Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="6">
                    <PieChart data={pieChartData} colors={pieChartColors} />
                  </Grid.Column>
                  <Grid.Column width="10" className="pl-0" verticalAlign="middle">
                    <List verticalAlign="middle">
                      <List.Item className="pb-2">
                        <Icon name="square" className="completed" /> Completed ({successCount})
                      </List.Item>
                      <List.Item className="pb-2">
                        <Icon name="square" className="abandoned" /> Abandoned ({failCount})
                      </List.Item>
                    </List>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width="8">
            <Segment className="time-taken">
              <Header as="h4">Time Taken</Header>
              <List className="mb-1">
                <List.Item className="pb-2">
                  <Label className="min" content="MIN" /> {getDurationInMMSS(Math.min(...timeTakenValues))}
                </List.Item>
                <List.Item className="pb-2">
                  <Label className="max" content="MAX" /> {getDurationInMMSS(Math.max(...timeTakenValues))}
                </List.Item>
                <List.Item>
                  <Label className="avg" content="AVG" /> {getDurationInMMSS(getAvgFromArray(timeTakenValues))}
                </List.Item>
              </List>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width="16">
            <Header as="h2" className="report-heading mb-1">
              Tasks{' '}
              <span className="font-weight-normal ml-2">
                | <small> Total Tasks ({totalTasks})</small>
              </span>
            </Header>
          </Grid.Column>
          <Grid.Column width="6">
            <Segment>
              <Header as="h4">Success Rate</Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="8" className="pr-0">
                    <DonutChart value={successRate} color="#32D090" />
                  </Grid.Column>
                  <Grid.Column width="8" className="pl-0">
                    <p>This chart shows the average success score across the tasks.</p>
                    <p className="m-0">
                      <strong>{successRate}%</strong> of the tasks got the right answer.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <Segment>
              <Header as="h4">Directness Rate</Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="8">
                    <DonutChart value={directnessRate} color="#2CBFCA" />
                  </Grid.Column>
                  <Grid.Column width="8" className="pl-0">
                    <p>This chart shows the average directness score across the tasks.</p>
                    <p className="m-0">
                      <strong>{directnessRate}%</strong> of answers were chosen without backtracking.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width="10" className="pl-0">
            <Segment className="by-task">
              <Header as="h4">
                By Task <Popup trigger={<Icon name="help circle" />} content="By Task Graph Help Text" position="top center" />
              </Header>
              <HorizontalBarChart data={barChartData} />
              <List className="graph-legends">
                <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Label basic className="border-none">
                    <Icon name="square" className="direct-success" /> Direct Success
                  </Label>
                  <Label basic className="border-none">
                    <Icon name="square" className="direct-failure" /> Direct Failure
                  </Label>
                  <Label basic className="border-none">
                    <Icon name="square" className="direct-skip" /> Direct Skip
                  </Label>
                </List.Item>
                <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Label basic className="border-none">
                    <Icon name="square" className="indirect-success" /> Indirect Success
                  </Label>
                  <Label basic className="border-none">
                    <Icon name="square" className="indirect-failure" /> Indirect Failure
                  </Label>
                  <Label basic className="border-none">
                    <Icon name="square" className="indirect-skip" /> Indirect Skip
                  </Label>
                </List.Item>
              </List>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

Overview.propTypes = {
  report: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  report: state.report
});

export default connect(mapStateToProps)(Overview);
