export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';

export const TESTS_LOADED = 'TESTS_LOADED';
export const SAVE_TEST_STATE = 'SAVE_TEST_STATE';
export const TEST_CREATED = 'TEST_CREATED';
export const TEST_UPDATED = 'TEST_UPDATED';
export const TEST_SAVING = 'TEST_SAVING';
export const TEST_DELETED = 'TEST_DELETED';

export const TASK_SAVING = 'TASK_SAVING';
export const UPDATE_TREE = 'UPDATE_TREE';

export const SURVEY_LOAD = 'SURVEY_LOAD';
export const SAVE_SURVEY_STATE = 'SAVE_SURVEY_STATE';
export const SURVEY_ADD_QUESTION = 'SURVEY_ADD_QUESTION';

export const REPORT_LOAD = 'REPORT_LOAD';
export const STUDY_LOAD = 'STUDY_LOAD';
export const STUDY_MESSAGES_LOAD = 'STUDY_MESSAGES_LOAD';
export const SAVING_TASK_REPONSE = 'SAVING_TASK_REPONSE';
export const STUDY_COMPLETE = 'STUDY_COMPLETE';
export const STUDY_PREVIEW = 'STUDY_PREVIEW';
export const STUDY_TEST_UPDATE = 'STUDY_TEST_UPDATE';
