import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

// Save State into LocalStorage
function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    console.log(e);
  }
}

// Load Saved state from LocalStorage
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return {};

    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return {};
  }
}

const initalState = loadFromLocalStorage();
// const initalState = {};

const middleware = [thunk];

const store = createStore(rootReducer, initalState, composeWithDevTools(applyMiddleware(...middleware)));

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});
export default store;
