import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Image, Grid, Button } from 'semantic-ui-react';
import { UXARMY_LOGO, OH_SNAP_ICON } from '../../utills/icons';
import '../../assets/css/messages.css';

const UXMessage = () => {
  return (
    <Grid className="messages no-navbar">
      <Grid.Row className="messages-header">
        <Grid.Column>
          <Image src={UXARMY_LOGO} size="small" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="messages-body">
        <Grid.Column width="16" textAlign="center">
          <Image src={OH_SNAP_ICON} centered />
          <Header as="h2" className="p-2 message-text">
            Oh Snap! Study Interrupted, Please start the test again.
          </Header>
          <Button as={Link} to="/" primary icon>
            Return to Home Page
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default UXMessage;
