import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Header, Container, Grid, Image, Input, Tab, Icon, Button, Menu } from 'semantic-ui-react';
import Moment from 'react-moment';
import { OVERVIEW_ICON, ANALYSIS_ICON } from '../../utills/icons';
import { getTestStatuslabelIcon } from '../../utills/testFunctions';
import { copyToClipboard } from '../../utills/common';
import { getTestDetails } from '../../actions/report';
import AnalysisPath from './AnalysisPath';
import AnalysisParticipants from './AnalysisParticipants';
import FirstClickDestinations from './FirstClickDestinations';
import Overview from './Overview';
import { setAlert } from '../../actions/alert';
import UXLoader from '../layouts/UXLoader';
import '../../assets/css/report.css';

const Report = ({ match: { params }, report: { response, test }, getTestDetails, setAlert }) => {
  const testId = params.testId;
  const studyLink = window.location.origin + '/treestudy/' + testId;

  // Load Test Report
  useEffect(() => {
    getTestDetails(testId);
  }, [getTestDetails, testId]);

  const inputRef = useRef(null);
  const copyShareLink = elementRef => {
    copyToClipboard(elementRef);
    setAlert('Link copied to clipboard!');
  };

  const totalTasks = test ? test.tasks.length : 0;
  const totalParticipants = response ? response.length : 0;

  const analysisPanes = [
    {
      menuItem: 'Participants',
      render: () => (
        <Tab.Pane attached={false} className="p-0 m-0 border-none shadow-none">
          <AnalysisParticipants />
        </Tab.Pane>
      )
    },
    {
      menuItem: <Menu.Item>
        First Click and Destinations <sup style={{color:"red", fontStyle: "italic"}}>WIP</sup>
      </Menu.Item>,
      render: () => (
        <Tab.Pane attached={false} className="p-0 m-0 border-none shadow-none">
          <FirstClickDestinations />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Path',
      render: () => (
        <Tab.Pane attached={false} className="p-0 m-0 border-none shadow-none">
          <AnalysisPath />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'questionnaire',
        name: 'Questionnaire Result',
        disabled: true
      },
      render: () => (
        <Tab.Pane attached={false} className="p-0 m-0 border-none shadow-none">
          Questionnaire
        </Tab.Pane>
      )
    }
  ];

  const panes = [
    {
      menuItem: {
        key: 'overview',
        content: (
          <div className="tab-title">
            <Image src={OVERVIEW_ICON} size="mini" verticalAlign="middle" /> Overview
          </div>
        )
      },
      render: () => (
        <Tab.Pane>
          <Overview />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'analysis',
        content: (
          <div className="tab-title">
            <Image src={ANALYSIS_ICON} size="mini" verticalAlign="middle" /> Analysis
          </div>
        )
      },
      render: () => (
        <Tab.Pane>
          <Container className="p-2">
            <Header as="h2" className="report-heading">
              Analysis{' '}
              <span className="font-weight-normal ml-2">
                | <small> Total Participants ({totalParticipants})</small> | <small>Total Tasks ({totalTasks})</small>
              </span>
            </Header>
            <Tab menu={{ secondary: true, pointing: true }} panes={analysisPanes} />
          </Container>
        </Tab.Pane>
      )
    }
  ];

  if (!test) {
    return <UXLoader />;
  }

  return (
    <Container className="report-container">
      <Grid className="m-0">
        <Grid.Row className="pt-3">
          <Grid.Column width="9">
            <Grid.Row>
              <Header as="h2" className="d-inline">
                <Link to={`/tests/${testId}`}>{test.name}</Link>
              </Header>
              <Image src={getTestStatuslabelIcon(test.status, false)} className="d-inline ml-2" />
            </Grid.Row>
            <Grid.Row>
              <span className="mr-1">Created By</span> |{' '}
              <Moment className="ml-1 font-weight-bold" format="DD MMMM YYYY">
                {test.startTime}
              </Moment>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column textAlign="right" width="7" className="pr-0">
            <Input type="text" placeholder="study link..." value={studyLink} readOnly action fluid ref={inputRef}>
              <input />
              <Button primary className="auto-width" icon onClick={() => copyShareLink(inputRef)}>
                <Icon name="copy" />
              </Button>
            </Input>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Tab
        menu={{ fluid: true, vertical: true }}
        menuPosition="left"
        panes={panes}
        className="test-setup pt-2"
        grid={{ paneWidth: 13, tabWidth: 3 }}
      />
    </Container>
  );
};

Report.propTypes = {
  getTestDetails: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  report: state.report
});

export default connect(
  mapStateToProps,
  { getTestDetails, setAlert }
)(Report);
