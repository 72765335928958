import axios from 'axios';
import { TESTS_LOADED, SAVE_TEST_STATE, TEST_CREATED, TEST_UPDATED, TEST_SAVING, TEST_DELETED } from './types';
import { setAlert } from './alert';
import { TEST_STATUS_DELETED, TEST_STATUS_ACTIVE, TEST_STATUS_INACTIVE, TEST_STATUS_EDIT } from './constants';

// Get All Tests
export const getAllTests = () => async dispatch => {
  try {
    const res = await axios.get('/api/account/tests');
    dispatch({ type: TESTS_LOADED, payload: res.data.tests });
  } catch (error) {
    dispatch({ type: TESTS_LOADED, payload: [] });
  }
};

// Get Test
export const getTest = (testId, isEdit) => async dispatch => {
  if (testId) {
    try {
      const res = await axios.get(`/api/test/${testId}`);
      const test = res.data;
      dispatch({ type: TEST_UPDATED, payload: res.data });
      if (isEdit && test.status !== TEST_STATUS_EDIT) dispatch(editTest(test));
    } catch (error) {
      console.log(error);
    }
  }
};

// Save Test State
export const saveTestState = test => dispatch => dispatch({ type: SAVE_TEST_STATE, payload: test });

// Save Test
export const saveTest = test => async dispatch => {
  dispatch({ type: TEST_SAVING, payload: true });

  try {
    const config = { headers: { 'Content-Type': 'application/json' } };

    const body = JSON.stringify(test);
    if (test._id) {
      await axios.put(`/api/test/${test._id}`, body, config);
      dispatch({ type: TEST_UPDATED, payload: test });
    } else {
      const res = await axios.post('/api/test', body, config);
      dispatch({ type: TEST_CREATED, payload: res.data });
    }
  } catch (error) {
    console.log(error);
  }
};

// Launch Test
export const launchTest = test => async dispatch => {
  dispatch({ type: TEST_SAVING, payload: true });

  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ status: TEST_STATUS_ACTIVE });

    await axios.put(`/api/test/${test._id}/status`, body, config);

    test.status = TEST_STATUS_ACTIVE;
    dispatch({ type: TEST_UPDATED, payload: test });
    dispatch(setAlert('Test has been launched successfully.'));
  } catch (error) {
    console.log(error);
  }
};

// Un-Launch Test
export const deactivateTest = test => async dispatch => {
  dispatch({ type: TEST_SAVING, payload: true });

  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ status: TEST_STATUS_INACTIVE });

    await axios.put(`/api/test/${test._id}/status`, body, config);

    test.status = TEST_STATUS_INACTIVE;
    dispatch({ type: TEST_UPDATED, payload: test });
    dispatch(setAlert('Test has been De-Activated successfully.'));
  } catch (error) {
    console.log(error);
  }
};

// Edit Test
export const editTest = test => async dispatch => {
  dispatch({ type: TEST_SAVING, payload: true });

  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ status: TEST_STATUS_EDIT });

    await axios.put(`/api/test/${test._id}/status`, body, config);

    test.status = TEST_STATUS_EDIT;
    dispatch({ type: TEST_UPDATED, payload: test });
  } catch (error) {
    console.log(error);
  }
};

// Delete Test
export const deleteTest = testId => async dispatch => {
  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ status: TEST_STATUS_DELETED });

    await axios.put(`/api/test/${testId}/status`, body, config);

    dispatch({ type: TEST_DELETED, payload: testId });
    dispatch(setAlert('Test deleted successfully.', 'success'));
  } catch (error) {
    console.log(error);
  }
};

// Duplicate Test
export const duplicateTest = testId => async dispatch => {
  dispatch(setAlert('Duplicate: ' + testId));
};
