import {
  ACTIVE_TEST_ICON,
  INACTIVE_TEST_ICON,
  DRAFT_TEST_ICON,
  COMPLETED_TEST_ICON,
  ACTIVE_TEST_LABEL,
  INACTIVE_TEST_LABEL,
  DRAFT_TEST_LABEL,
  COMPLETED_TEST_LABEL
} from './icons';

// Get Test Status Icon
export const getTestStatuslabelIcon = (status, icon = true) => {
  const labels = {
    0: { icon: DRAFT_TEST_ICON, label: DRAFT_TEST_LABEL },
    1: { icon: ACTIVE_TEST_ICON, label: ACTIVE_TEST_LABEL },
    2: { icon: INACTIVE_TEST_ICON, label: INACTIVE_TEST_LABEL },
    3: { icon: DRAFT_TEST_ICON, label: DRAFT_TEST_LABEL },
    4: { icon: COMPLETED_TEST_ICON, label: COMPLETED_TEST_LABEL }
  };

  return icon ? (labels[status] ? labels[status].icon : DRAFT_TEST_ICON) : labels[status] ? labels[status].label : DRAFT_TEST_LABEL;
};

// Get Test Status Text
export const getTestStatuslabelText = status => {
  const labelText = {
    0: 'Draft',
    1: 'Active',
    2: 'Inactive',
    3: 'In-Edit',
    4: 'Completed'
  };

  return labelText[status] ? labelText[status] : 'Draft';
};

// Return true/false and alertMessage if showAlertMessage is true.
export const isTestLaunchable = (test, showAlertMessage = false) => {
  if (!test || !test._id) return showAlertMessage ? 'Invalid test!' : false;

  const TEST_TREE_ALERT = 'Please create at least one child nodes in tree to launch the test.';
  if (test.tree) {
    const rootNode = test.tree.treeData[0];
    if (rootNode.children.length === 0) return showAlertMessage ? TEST_TREE_ALERT : false;
  } else {
    return showAlertMessage ? TEST_TREE_ALERT : false;
  }

  const NO_TASK_ALERT = 'Please create at least one task to launch the test.';
  if (test.tasks) {
    if (test.tasks.length === 0) {
      return showAlertMessage ? NO_TASK_ALERT : false;
    } else {
      let unAnswerTasks = test.tasks.filter(task => !task.answers || task.answers.length === 0);
      if (unAnswerTasks.length > 0) return showAlertMessage ? 'Please make sure to define the answer for each task.' : false;
    }
  } else {
    return showAlertMessage ? NO_TASK_ALERT : false;
  }

  return showAlertMessage ? '' : true;
};
