import { REPORT_LOAD } from '../actions/types';

const initialState = { test: null, response: [] };

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REPORT_LOAD:
      return { ...state, ...payload };
    default:
      return state;
  }
}
