import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Header, Select, Grid, Table, Icon, List, Dropdown, Label } from 'semantic-ui-react';
import { getNodePath } from '../../utills/treeFunctions';
import { getParticipantEmail } from '../../utills/reportFunctions';
import {
  RESULT_DIRECT_SUCCESS,
  RESULT_INDIRECT_SUCCESS,
  RESULT_DIRECT_FAILURE,
  RESULT_INDIRECT_FAILURE,
  RESULT_DIRECT_SKIP,
  RESULT_INDIRECT_SKIP
} from '../../actions/constants';

const AnalysisPath = ({ report: { response, test } }) => {
  // console.log(test, response);

  // State Management
  const [state, setState] = useState({ activeTaskIndex: 0, filterBy: null });

  const { activeTaskIndex, filterBy } = state;
  const treeData = test.tree.treeData;
  const tasks = test.tasks;
  const task = tasks[activeTaskIndex];

  // Get AnalysisPath Results
  const analysisPathResults = response.map(res => {
    let answer = '';
    let result = 0;
    if (res.taskAnswers.length === 0) {
      answer = '-';
    } else {
      const userResult = res.taskAnswers.filter(item => item.id === task._id);
      answer = userResult.length === 0 ? '-' : userResult[0].answer.map(ans => ans.label).join(' > ');
      result = userResult.length === 0 ? result : userResult[0].result;
    }
    return { result, answer, userEmail: getParticipantEmail(res.participant) };
  });

  // Task Selection Dropdown
  const tasksOptions = tasks.map((task, index) => {
    return { key: index, value: index, text: `Task ${index + 1}` };
  });

  // Get Result ClassName
  const taskResultClassName = status => {
    switch (status) {
      case 1:
        return 'direct-success';
      case 2:
        return 'indirect-success';
      case 3:
        return 'direct-failure';
      case 4:
        return 'indirect-failure';
      case 5:
        return 'direct-skip';
      case 6:
        return 'indirect-skip';
      default:
        return 'direct-failure';
    }
  };

  // Success Labels
  const labelOptions = [
    { key: 'dscs', text: 'Direct Success', value: RESULT_DIRECT_SUCCESS, className: 'direct-success', icon: 'square' },
    { key: 'idscs', text: 'Indirect Success', value: RESULT_INDIRECT_SUCCESS, className: 'indirect-success' },
    { key: 'dfl', text: 'Direct Failure', value: RESULT_DIRECT_FAILURE, className: 'direct-failure' },
    { key: 'idfl', text: 'Indirect Failure', value: RESULT_INDIRECT_FAILURE, className: 'indirect-failure' },
    { key: 'dskp', text: 'Direct Skip', value: RESULT_DIRECT_SKIP, className: 'direct-skip' },
    { key: 'idskp', text: 'Indirect Skip', value: RESULT_INDIRECT_SKIP, className: 'indirect-skip' }
  ];

  return (
    <Container>
      <Select
        placeholder="Select Task"
        options={tasksOptions}
        defaultValue={activeTaskIndex}
        onChange={(e, d) => setState({ ...state, activeTaskIndex: d.value, filterBy: null })}
      />
      <Grid className="pt-3">
        <Grid.Row>
          <Grid.Column width="8">
            <Header as="h4">
              <Icon name="tasks" />
              <Header.Content>Task Description:</Header.Content>
            </Header>
            <p className="pl-3">{task.description}</p>
          </Grid.Column>
          <Grid.Column width="8">
            <Header as="h4">
              <Icon name="check" />
              <Header.Content>Correct Destinations:</Header.Content>
            </Header>
            <List className="pl-3">
              {task.answers.map((answer, index) => (
                <List.Item key={index}>{getNodePath(treeData, answer, true)}</List.Item>
              ))}
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle" className="space-between p-2">
          {labelOptions.map(item => (
            <Label basic className="border-none" key={item.key}>
              <Icon name="square" className={item.className} /> {item.text}
            </Label>
          ))}
        </Grid.Row>
      </Grid>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="2" textAlign="center">
              <Dropdown text="Task Status">
                <Dropdown.Menu>
                  {labelOptions.map(item => (
                    <Dropdown.Item
                      key={item.key}
                      onClick={(e, d) => setState({ ...state, filterBy: item.value })}
                      active={filterBy === item.value}
                    >
                      <Icon name="square" className={item.className} /> {item.text}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Table.HeaderCell>
            <Table.HeaderCell width="2">Participant</Table.HeaderCell>
            <Table.HeaderCell width="12">Paths</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {analysisPathResults.length !== 0 ? (
            analysisPathResults.map((item, index) => (
              <Table.Row key={index} disabled={filterBy && filterBy !== item.result ? true : false}>
                <Table.Cell textAlign="center">
                  <Icon
                    name="square"
                    className={taskResultClassName(item.result)}
                    disabled={filterBy && filterBy !== item.result ? true : false}
                  />
                </Table.Cell>
                <Table.Cell>{item.userEmail}</Table.Cell>
                <Table.Cell>{item.answer}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3">
                <Icon name="info circle" color="green" /> No responses available!
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Container>
  );
};

AnalysisPath.propTypes = {
  report: PropTypes.object
};

const mapStateToProps = state => ({
  report: state.report
});

export default connect(mapStateToProps)(AnalysisPath);
