import { SURVEY_LOAD, SAVE_SURVEY_STATE, SURVEY_ADD_QUESTION } from '../actions/types';

const initialState = { survey: null };

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SURVEY_LOAD:
      return { ...state, ...payload };
    case SAVE_SURVEY_STATE:
      return { ...state, survey: payload };
    case SURVEY_ADD_QUESTION:
      return { ...state };
    default:
      return state;
  }
}
