import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Tests from './components/tests';
import NewTest from './components/tests/NewTest';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import UXMessage from './components/layouts/UXMessage';
import NotFound from './components/layouts/NotFound';
import Report from './components/report';
import TreeStudy from './components/treestudy/participantForm';
import StudyBegin from './components/treestudy/testerView';
import StudyInterrupted from './components/treestudy/studyInterrupted';
import PrivateRoute from './components/routing/PrivateRoute';
import Alert from './components/layouts/Alert';
import './assets/css/App.css';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utills/setAuthToken';

setAuthToken(localStorage.token);

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Alert />
          <Switch>
            <PrivateRoute exact path="/" component={Tests} />
            <PrivateRoute exact path="/tests" component={Tests} />
            <PrivateRoute exact path="/tests/new" component={NewTest} />
            <PrivateRoute exact path="/tests/report/:testId" component={Report} />
            <PrivateRoute exact path="/tests/:testId" component={NewTest} />
            <PrivateRoute exact path="/tests/:testId/:action" component={NewTest} />

            <Route exact path="/login" component={Login} />
            <Route exact path="/sign-up-sjwww" component={Register} />
            <Route exact path="/message" component={UXMessage} />
            <Route exact path="/treestudy/begin" component={StudyBegin} />
            <Route exact path="/treestudy/interrupted" component={StudyInterrupted} />
            <Route exact path="/treestudy/:testId" component={TreeStudy} />
            <Route exact path="/treestudy/:testId/:action" component={TreeStudy} />
            <Route path="" component={NotFound} />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
