import axios from 'axios';
import setAuthToken from '../utills/setAuthToken';
import { setAlert } from './alert';
import { REGISTER_SUCCESS, REGISTER_FAILED, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT } from './types';
import moment from 'moment';
import 'moment-timezone';

// Load User
export const loadUser = () => async dispatch => {
  localStorage.token && setAuthToken(localStorage.token);
  try {
    const res = await axios.get('/api/auth');
    dispatch({ type: USER_LOADED, payload: res.data });
  } catch (error) {
    dispatch({ type: AUTH_ERROR });
  }
};

// Register User
export const register = (firstname, lastname, email, password) => async dispatch => {
  try {
    const config = { headers: { 'Content-Type': 'application/json' } };

    const timezone = moment.tz.guess();

    const body = JSON.stringify({ firstname, lastname, email, password, timezone });

    const result = await axios.post('/api/users/register', body, config);
    dispatch({ type: REGISTER_SUCCESS, payload: result.data });
    dispatch(loadUser());
    dispatch(setAlert('User registered successfully'));
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.errors) {
      const errors = err.response.data.errors;
      errors.forEach(error => {
        dispatch(setAlert(error.msg, 'error', 5000));
      });
    }
    dispatch({ type: REGISTER_FAILED });
  }
};

// Login User
export const login = (email, password, remember) => async dispatch => {
  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ email, password, remember });

    const result = await axios.post('/api/auth', body, config);
    dispatch({ type: LOGIN_SUCCESS, payload: result.data });
    dispatch(loadUser());
    dispatch(setAlert('Logged in successfully', 'success'));
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.errors) {
      const errors = err.response.data.errors;
      errors.forEach(error => {
        dispatch(setAlert(error.msg, 'error', 5000));
      });
    }
    dispatch({ type: LOGIN_FAILED });
  }
};

// User logout
export const logout = () => dispatch => dispatch({ type: LOGOUT });
