import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Icon, Popup, Grid } from 'semantic-ui-react';
import Survey from '../layouts/Survey';

const Questionnaire = ({ test, questionnaire }) => {
  return (
    <Grid className="p-2">
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as="h3">
            Pre-Study Questionnaire
            <Popup content="Pre-Study Questionnaire Text" trigger={<Icon name="help circle" size="tiny" />} position="top center" />
          </Header>
          <Survey surveyType="pre" test={test} />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as="h3">
            Post-Study Questionnaire{' '}
            <Popup content="Post-Study Questionnaire Text" trigger={<Icon name="help circle" size="tiny" />} position="top center" />
          </Header>
          <Survey surveyType="post" test={test} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

Questionnaire.propTypes = {
  questionnaire: PropTypes.object,
  test: PropTypes.object
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaire,
  test: state.test.test
});

export default connect(
  mapStateToProps,
  null
)(Questionnaire);
