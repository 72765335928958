import React, { useState, Fragment } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import { dirctnessLabel } from '../../utills/reportFunctions';

const HorizontalBarChart = ({ data }) => {
  const [activeBar, setState] = useState(null);

  const CustomTooltip = ({ active, payload }) => {
    if (active && activeBar && payload[0]) {
      const data = payload[0];
      const barData = data.payload;

      let total = 0;
      for (let i in barData) if (Number.isInteger(barData[i])) total += barData[i];

      return (
        <div style={{ background: '#212121', color: '#ffffff', padding: '0.5rem 1rem', borderRadius: '4px' }}>
          {`${((barData[activeBar] / total) * 100).toFixed(0)}% ${dirctnessLabel(activeBar)}`}
        </div>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <BarChart data={data} layout="vertical" width={540} height={250}>
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" />
        <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
        <Bar
          dataKey="directSuccess"
          stackId="a"
          fill="#32D090"
          onMouseOver={e => setState('directSuccess')}
          onMouseOut={() => setState(null)}
        />
        <Bar
          dataKey="indirectSuccess"
          stackId="a"
          fill="#ADF4D7"
          onMouseOver={e => setState('indirectSuccess')}
          onMouseOut={() => setState(null)}
        />
        <Bar
          dataKey="directFail"
          stackId="a"
          fill="#FA3666"
          onMouseOver={e => setState('directFail')}
          onMouseOut={() => setState(null)}
        />
        <Bar
          dataKey="indirectFail"
          stackId="a"
          fill="#FFA8B5"
          onMouseOver={e => setState('indirectFail')}
          onMouseOut={() => setState(null)}
        />
        <Bar
          dataKey="directSkip"
          stackId="a"
          fill="#3B88F5"
          onMouseOver={e => setState('directSkip')}
          onMouseOut={() => setState(null)}
        />
        <Bar
          dataKey="indirectSkip"
          stackId="a"
          fill="#B1CFFB"
          onMouseOver={e => setState('indirectSkip')}
          onMouseOut={() => setState(null)}
        />
      </BarChart>
    </Fragment>
  );
};

export default HorizontalBarChart;
