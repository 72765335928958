import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Button, Segment, List, Icon, Popup, Modal, Image, Form, Card, Grid, Tab, Container } from 'semantic-ui-react';
import { CLOSE_ICON } from '../../utills/icons';
import { filterAnswers, getNodePath, treeMotion } from '../../utills/treeFunctions';
import { saveTask, deleteTask } from '../../actions/task';
import Survey from '../layouts/Survey';
import UXLoader from '../layouts/UXLoader';
import '../../assets/css/tasks.css';
import tooltips from '../../utills/tooltips';

// RC Tree
import 'rc-tree/assets/index.css';
import Tree, { TreeNode } from 'rc-tree';

const Tasks = ({ isPreview, test: { test, isSavingTask }, saveTask, deleteTask }) => {
  const tasks = test.tasks;
  const treeData = test.tree.treeData;

  const [taskData, updateTaskData] = useState({ openTaskModel: false, defineAnswer: false, task: {} });
  const { openTaskModel, defineAnswer, task } = taskData;

  const toggleTaskModel = (openTaskModel = true) => updateTaskData({ ...taskData, openTaskModel, defineAnswer: false, task: {} });
  const saveTheTask = () => {
    saveTask(task, test);
    toggleTaskModel(false);
  };
  const defineTaskAnser = () => updateTaskData({ ...taskData, defineAnswer: true });
  const saveTaskDescription = d => updateTaskData({ ...taskData, task: { ...task, description: d.value } });
  const editTask = (task, index) => updateTaskData({ ...taskData, task: { ...task, name: `Task ${index + 1}` }, openTaskModel: true });
  const saveTaskAnswer = answers => {
    // filter right answers becoz user may have update the tree and current node may not be the leaf node.
    answers = filterAnswers(treeData, answers);
    updateTaskData({ ...taskData, task: { ...task, answers } });
  };

  // Task Default Values
  task.name = task.name || `Task ${tasks.length + 1}`;
  task.description = task.description || '';
  task.answers = task.answers || [];

  // Tree Data
  const loopTreeNodes = data => {
    return data.map(item => {
      const isLeaf = item.children.length > 0 ? false : true;
      if (item.children) {
        return (
          <TreeNode title={item.name} key={item.id} isLeaf={isLeaf} checkable={isLeaf} className={isLeaf ? 'leaf-el' : 'parent-el'}>
            {loopTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode title={item.name} key={item.id} isLeaf={isLeaf} checkable={isLeaf} className={isLeaf ? 'leaf-el' : 'parent-el'} />
      );
    });
  };
  const treeNodes = loopTreeNodes(treeData);
  const switcherIcon = obj => {
    if (obj.isLeaf === true) return '';
    return <Icon name={obj.expanded === true ? 'caret down' : 'caret right'} />;
  };

  const surveyPanes = [
    {
      menuItem: 'Pre-Task Questions',
      render: () => (
        <Tab.Pane attached={false} className="p-0 m-0 border-none shadow-none">
          <Survey surveyType="pre" task={task} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Post-Task Questions',
      render: () => (
        <Tab.Pane attached={false} className="p-0 m-0 border-none shadow-none">
          <Survey surveyType="post" task={task} />
        </Tab.Pane>
      )
    }
  ];

  const taskPanes = [
    {
      menuItem: 'Task',
      render: () => (
        <Tab.Pane attached={false} className="p-0 m-0 border-none shadow-none">
          <Form>
            <Form.TextArea
              name="description"
              onChange={(e, d) => saveTaskDescription(d)}
              value={task.description}
              placeholder="Enter your task here..."
              rows="5"
            />
          </Form>
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'survey',
        disabled: true,
        content: (
          <Popup
            content="Please save the task first."
            trigger={<span>Survey</span>}
            position="top center"
            disabled={!task._id ? true : false}
          />
        )
      },
      render: () => (
        <Tab.Pane attached={false} className="p-0 m-0 border-none shadow-none">
          <Tab menu={{ secondary: true, pointing: true }} panes={surveyPanes} />
          {/* <Button primary floated="right">Done</Button> */}
        </Tab.Pane>
      )
    }
  ];

  if (isSavingTask) {
    return <UXLoader />;
  }

  return (
    <Segment>
      <Header as="h2">
        Task <Popup content={tooltips.testTaskHelp} trigger={<Icon name="help circle" size="tiny" />} position="top center" />
      </Header>
      <Button primary basic fluid onClick={() => toggleTaskModel()} disabled={isPreview}>
        + Insert Task
      </Button>
      <Header as="h5" className="mt-2">
        Task List ({tasks.length})
      </Header>

      <Container className="tasks-list">
        {tasks.length > 0 &&
          tasks.map((task, index) => (
            <Card className="task-card" key={index}>
              <Card.Content>
                <Card.Header>
                  Task {index + 1}
                  <Icon name="edit" className="ml-1 pointer" onClick={() => editTask(task, index)} disabled={isPreview} />{' '}
                  <Icon
                    name="trash alternate outline"
                    className="pointer"
                    onClick={() => deleteTask(task._id, test)}
                    disabled={isPreview}
                  />
                </Card.Header>
                <Card.Description>{task.description}</Card.Description>
              </Card.Content>
              <Card.Content extra className="p-1">
                <List>
                  {task.answers && task.answers.length > 0 ? (
                    task.answers.map((answer, index) => <List.Item key={index}>{getNodePath(treeData, answer, true)}</List.Item>)
                  ) : (
                    <List.Item>
                      <i>No answers to be shown</i>
                    </List.Item>
                  )}
                </List>
              </Card.Content>
            </Card>
          ))}
      </Container>

      {task && (
        <Modal
          open={openTaskModel}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={() => toggleTaskModel(false)}
          size="tiny"
          centered
        >
          <Modal.Header className="bg-white border-none pb-0">
            <Icon name="edit" /> {task.name}
            {defineAnswer && (
              <Header as="h3" className="m-0 d-inline" style={{ position: 'relative', left: '7.5em' }}>
                Define Answer
              </Header>
            )}
            <Image src={CLOSE_ICON} onClick={() => toggleTaskModel(false)} floated="right" className="pointer" />
          </Modal.Header>
          <Modal.Content scrolling>
            {!defineAnswer ? (
              <Tab menu={{ secondary: false, className: 'task-survey-tab' }} panes={taskPanes} />
            ) : (
              <Grid>
                <Grid.Row className="pb-0">
                  <Grid.Column width={6} className="p-2" style={{ backgroundColor: '#F8FAF9', minHeight: '9.5rem' }}>
                    <p>{task.description}</p>
                    <List>
                      {task.answers && task.answers.length > 0 ? (
                        task.answers.map((answer, index) => <List.Item key={index}>{getNodePath(treeData, answer, true)}</List.Item>)
                      ) : (
                        <List.Item>
                          <i>No answers to be shown</i>
                        </List.Item>
                      )}
                    </List>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    {/* <Input name="search" type="search" placeholder="Search tree" icon="search" fluid width={16} /> */}
                    <Tree
                      motion={treeMotion}
                      switcherIcon={switcherIcon}
                      defaultExpandAll
                      autoExpandParent
                      checkable
                      checkStrictly
                      showIcon={false}
                      defaultCheckedKeys={task.answers}
                      onCheck={(checkedKeys, e) => saveTaskAnswer(checkedKeys.checked)}
                    >
                      {treeNodes}
                    </Tree>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Modal.Content>
          <Modal.Actions className="bg-white border-none">
            <Button onClick={() => defineTaskAnser()} content="Define Answer" basic color="black" disabled={!task.description} />
            <Button onClick={() => saveTheTask()} content="Save" primary disabled={!task.description} />
          </Modal.Actions>
        </Modal>
      )}
    </Segment>
  );
};

Tasks.propTypes = {
  saveTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  test: PropTypes.object
};

const mapStateToProps = state => ({ test: state.test });

export default connect(
  mapStateToProps,
  { saveTask, deleteTask }
)(Tasks);
