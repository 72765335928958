import { SURVEY_LOAD, SAVE_SURVEY_STATE, SURVEY_ADD_QUESTION } from './types';

// All Surveys
export const loadSurveys = (test, task) => dispatch =>
  dispatch({ type: SURVEY_LOAD, payload: (task && task.survey) || (test && test.questionnaire) });

// Save Survey State
export const saveSurveyState = survey => dispatch => dispatch({ type: SAVE_SURVEY_STATE, payload: survey });

// Save Survey
export const saveSurvey = (survey, test, task) => dispatch => {
  if (task) {
    console.log('Task::', task, survey);
  } else if (test) {
    console.log('Test::', test, survey);
  }

  // dispatch({ type: SAVE_SURVEY_STATE, payload: survey });
};

// Add New Question
export const addQuestion = () => dispatch => dispatch({ type: SURVEY_ADD_QUESTION });
