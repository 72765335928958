import {
  STUDY_LOAD,
  STUDY_MESSAGES_LOAD,
  SAVING_TASK_REPONSE,
  STUDY_COMPLETE,
  STUDY_PREVIEW,
  STUDY_TEST_UPDATE
} from '../actions/types';

const defaultTestData = { showGuideModal: true, showConfirmationModal: false, taskIndex: 0, taskAnswer: {} };

const initialState = { test: {}, participant: null, messages: null, isSavingTask: false, isPreview: false, testData: defaultTestData };

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STUDY_LOAD:
      return { ...state, ...payload };
    case STUDY_PREVIEW:
      return { ...state, isPreview: payload };
    case STUDY_MESSAGES_LOAD:
      return { ...state, messages: payload };
    case SAVING_TASK_REPONSE:
      return { ...state, isSavingTask: payload };
    case STUDY_COMPLETE:
      return {
        ...state,
        test: {},
        participant: null,
        messages: null,
        isSavingTask: false,
        isPreview: false,
        testData: defaultTestData
      };
    case STUDY_TEST_UPDATE:
      if (payload.isNew) {
        let taskIndex = payload.taskIndex ? payload.taskIndex : state.testData.taskIndex;
        let taskAnswer = {
          id: state.test.tasks[taskIndex]._id,
          startTime: new Date().getTime(),
          answer: [{ id: state.test.tree.treeData[0].id, label: state.test.tree.treeData[0].name }]
        };

        return { ...state, testData: { ...state.testData, taskIndex, taskAnswer } };
      }

      return { ...state, testData: payload };
    default:
      return state;
  }
}
