import moment from 'moment';

export const getParticipantEmail = participant => {
  return participant.email ? participant.email : '-';
};

// Returns duration in Seconds
export const calculateTimeTaken = (start, end, durationOnly = false) => {
  let duration = moment.duration(moment(end).diff(moment(start)));
  return durationOnly ? duration.asSeconds() : getDurationInMMSS(duration);
};

export const getDurationInMMSS = duration => {
  if (!isFinite(duration)) return 'Not available yet!';

  if (!isNaN(parseInt(duration))) duration = moment.duration(duration * 1000);

  let hhmmss = '';
  if (duration.get('hours') > 0) hhmmss += duration.get('hours') + ' Hour ';
  if (duration.get('minutes') > 0) hhmmss += duration.get('minutes') + ' Min ';
  if (duration.get('seconds') >= 0) hhmmss += duration.get('seconds') + ' Sec';

  return hhmmss.trim();
};

export const dirctnessLabel = type => {
  const labels = {
    directSuccess: 'Direct Success',
    indirectSuccess: 'Indirect Success',
    directFail: 'Direct Fail',
    indirectFail: 'Indirect Fail',
    directSkip: 'Direct Skip',
    indirectSkip: 'Indirect Skip'
  };

  return labels[type] ? labels[type] : '';
};

export const getResultType = result => {
  const types = {
    1: 'directSuccess',
    2: 'indirectSuccess',
    3: 'directFail',
    4: 'indirectFail',
    5: 'directSkip',
    6: 'indirectSkip'
  };

  return types[result] ? types[result] : 'directFail';
};
