const tooltip = {
  testName: '',
  testExpireDate: '',
  testMessages: {
    greeting: 'Participants will see this message when they first arrive to the study.',
    thankYou: 'Participants see this message after completing the study.',
    instructions: 'This message is shown to each participant before they begin your tasks.'
  },
  testShareLink:
    'Place this link onto your website, send an email invitation to existing contacts, customers or users, or share on social media.',
  testTreeHelp:
    "The 'tree' is the information structure for your website. In a basic sense you can think of it like your sitemap. Each leaf item in your tree will be a possible answer for the tasks you set for this test.",
  testTaskHelp: 'This is where you define your tasks and their correct answers.'
};

export default tooltip;
