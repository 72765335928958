import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Icon, Popup, Image, Container } from 'semantic-ui-react';
import { TREE_IMPORT_ICON } from '../../utills/icons';
import { uniqueID } from '../../utills/common';
import { treeMotion, getDefaultTree } from '../../utills/treeFunctions';
import { updateTestTree, deleteTestTree } from '../../actions/tree';
import UXConfirm from '../layouts/UXConfirm';
import tooltips from '../../utills/tooltips';

// RC Tree
import 'rc-tree/assets/index.css';
import Tree, { TreeNode } from 'rc-tree';

const TestTree = ({ isPreview, test, updateTestTree, deleteTestTree }) => {
  const [confirmConfig, setConfirmConfig] = useState({ confirmMessage: null, confirmCallback: null });
  const { confirmMessage, confirmCallback } = confirmConfig;

  // Disable tree action while node adding/editing
  const [isNodeInEdit, setIsNodeInEdit] = useState(false);

  // Default Tree if not Exists
  test.tree = test.tree || getDefaultTree();

  const treeData = test.tree.treeData;

  // Default Expanded Keys && Open home node by default
  const [defaultExpandedNodes, setDefaultExpandedNodes] = useState([treeData[0].id]);

  // Function to find the item position in an array
  const loopData = (data, id, callback) => {
    data.forEach((item, index, arr) => {
      if (item.id === id) {
        callback(item, index, arr);
        return;
      }
      if (item.children) {
        loopData(item.children, id, callback);
      }
    });
  };

  // Perform action on Node Drag and Drop
  const onDrop = info => {
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    // Find dragObject
    let dragObj;
    loopData(treeData, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loopData(treeData, dropKey, item => {
        item.children = item.children || [];
        // where to insert
        dragObj.parentId = item.id;
        item.children.push(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loopData(treeData, dropKey, item => {
        item.children = item.children || [];
        // where to insert
        dragObj.parentId = item.id;
        item.children.unshift(dragObj);
      });
    } else {
      // Drop on the gap
      let ar;
      let i;
      loopData(treeData, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
        dragObj.parentId = item.id;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    updateTestTree(test);
  };

  // Add a new node
  const addTreeNode = node => {
    // Find dragObject
    loopData(treeData, node.id, (item, index, arr) => {
      const newObj = { id: uniqueID(), name: 'New Child', isLeaf: true, children: [], editable: true, parentId: node.id };
      node.children.push(newObj);
      node.isLeaf = false;
      arr.splice(index, 1, node);
      updateTestTree(test, true);
      setIsNodeInEdit(true);
      setDefaultExpandedNodes([...defaultExpandedNodes, node.id]);
    });
  };

  // Remove a node
  const removeTreeNode = node => {
    // Find dragObject
    loopData(treeData, node.id, (item, index, arr) => {
      arr.splice(index, 1);
      updateTestTree(test);
    });
  };

  // Enable a rename for node
  const renameTreeNode = node => {
    // Find dragObject
    loopData(treeData, node.id, (item, index, arr) => {
      const newNode = { ...node, editable: true };
      arr.splice(index, 1, newNode);
      updateTestTree(test, true);
      setIsNodeInEdit(true);
    });
  };

  // Rename a node
  let renameRef;
  const saveRenamedText = node => {
    // Find dragObject
    loopData(treeData, node.id, (item, index, arr) => {
      const newNode = { ...node, name: renameRef.value ? renameRef.value : 'New Child', editable: false };
      arr.splice(index, 1, newNode);
      updateTestTree(test);
      setIsNodeInEdit(false);
    });
  };

  // Node Actions ex Add, Delete, Rename
  const nodeActions = node => (
    <Fragment>
      {node.editable ? (
        <form
          onSubmit={e => {
            e.preventDefault();
            saveRenamedText(node);
          }}
          className="d-inline"
        >
          <input
            className="border-none pl-1"
            placeholder="Label Name"
            type="text"
            ref={el => (renameRef = el)}
            onBlur={() => saveRenamedText(node)}
            onFocus={e => {
              e.persist();
              e.target.value = node.name;
            }}
            autoFocus
          />
        </form>
      ) : (
        node.name
      )}
      <div className={isPreview ? 'isPreview node-actions' : 'node-actions'}>
        <Popup
          content="Rename"
          trigger={<Icon name="edit" className="pointer" onClick={() => renameTreeNode(node)} disabled={isNodeInEdit} />}
          position="top center"
          inverted
        />
        <Popup
          content="Add Node"
          trigger={<Icon name="plus circle" className="pointer" onClick={() => addTreeNode(node)} disabled={isNodeInEdit} />}
          position="top center"
          inverted
        />
        <Popup
          content="Remove Node"
          trigger={
            <Icon
              name="minus circle"
              className="pointer"
              onClick={() => removeTreeNode(node)}
              disabled={!node.parentId || isNodeInEdit}
            />
          }
          position="top center"
          inverted
          disabled={!node.parentId}
        />
      </div>
    </Fragment>
  );

  const loopTreeNodes = data => {
    return data.map(item => {
      const isLeaf = item.children.length > 0 ? false : true;
      if (item.children) {
        return (
          <TreeNode title={nodeActions(item)} key={item.id} isLeaf={isLeaf} className={isLeaf ? 'leaf-el' : 'parent-el'}>
            {loopTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode title={nodeActions(item)} key={item.id} isLeaf={isLeaf} className={isLeaf ? 'leaf-el' : 'parent-el'} />;
    });
  };
  const treeNodes = loopTreeNodes(treeData);

  const switcherIcon = obj => {
    if (obj.isLeaf === true) return '';
    return <Icon name={obj.expanded === true ? 'caret down' : 'caret right'} />;
  };

  // Import Tree
  const importTree = () => console.log('Import Tree');

  // Delete Tree
  const deleteTreeMessage =
    'Do you wish to delete the whole tree? This action cannot be undone. All tasks answer will also be deleted.';
  const deleteTree = isSuccess => {
    setConfirmConfig({ ...confirmConfig, confirmMessage: null, confirmCallback: null });
    isSuccess && deleteTestTree(test);
  };

  return (
    <Fragment>
      <Header as="h2" className="d-inline">
        Tree <Popup content={tooltips.testTreeHelp} trigger={<Icon name="help circle" size="tiny" />} position="top center" />
      </Header>
      <div className="tree-actions">
        <Popup
          trigger={<Image src={TREE_IMPORT_ICON} floated="right" onClick={() => importTree()} className="pointer" disabled />}
          content="Import Tree"
          position="top center"
          inverted
        />
        <Popup
          trigger={
            <Icon
              name="trash alternate outline"
              onClick={() =>
                treeData[0].children.length &&
                setConfirmConfig({ ...confirmConfig, confirmMessage: deleteTreeMessage, confirmCallback: deleteTree })
              }
              link
              className="ml-1"
              disabled={isPreview}
            />
          }
          content={treeData[0].children.length ? 'Delete Tree' : 'No Tree to Delete'}
          position="top center"
          inverted
        />
        <UXConfirm confirmMessage={confirmMessage} callback={confirmCallback} />
      </div>
      <Container className="tree-container">
        <Tree
          motion={treeMotion}
          switcherIcon={switcherIcon}
          draggable
          showIcon={false}
          onDrop={info => onDrop(info)}
          onExpand={info => setDefaultExpandedNodes(info)}
          expandedKeys={defaultExpandedNodes}
        >
          {treeNodes}
        </Tree>
      </Container>
    </Fragment>
  );
};

TestTree.propTypes = {
  updateTestTree: PropTypes.func.isRequired,
  deleteTestTree: PropTypes.func.isRequired,
  test: PropTypes.object
};

const mapStateToProps = state => ({
  test: state.test.test
});

export default connect(
  mapStateToProps,
  { updateTestTree, deleteTestTree }
)(TestTree);
