import axios from 'axios';
import { STUDY_MESSAGES_LOAD, STUDY_LOAD, SAVING_TASK_REPONSE, STUDY_PREVIEW, STUDY_COMPLETE, STUDY_TEST_UPDATE } from './types';

// Verify Test
export const verifyTest = (testId, isPreview) => async dispatch => {
  try {
    const res = await axios.get(`/api/test/check/${testId}`);
    dispatch({ type: STUDY_MESSAGES_LOAD, payload: res.data });
    dispatch({ type: STUDY_PREVIEW, payload: isPreview });
  } catch (error) {
    console.log(error);
  }
};

// Save Participant
export const saveParticipant = (participant, testId, isPreview) => async dispatch => {
  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ participant, isPreview });
    const res = await axios.post(`/api/response/${testId}`, body, config);
    const responseId = isPreview ? `res_${new Date().getTime()}` : res.data.response_id;
    const payload = { participant: { ...participant, responseId }, test: res.data.test };
    dispatch({ type: STUDY_LOAD, payload });
    dispatch({ type: STUDY_TEST_UPDATE, payload: { isNew: true } });
  } catch (error) {
    console.log(error);
  }
};

// Save Task Reponse
export const saveTaskResponse = (responseId, taskAnswers, isPreview) => async dispatch => {
  dispatch({ type: SAVING_TASK_REPONSE, payload: true });
  if (isPreview) {
    setTimeout(() => dispatch({ type: SAVING_TASK_REPONSE, payload: false }), 250);
    return;
  }

  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const body = JSON.stringify({ taskAnswers });
    await axios.put(`/api/response/${responseId}`, body, config);
    dispatch({ type: SAVING_TASK_REPONSE, payload: false });
  } catch (error) {
    console.log(error);
  }
};

// Mark Test Complete
export const markTestComplete = (responseId, isPreview) => async dispatch => {
  dispatch({ type: SAVING_TASK_REPONSE, payload: true });
  if (isPreview) {
    setTimeout(() => dispatch({ type: SAVING_TASK_REPONSE, payload: false }), 250);
    return;
  }

  try {
    await axios.put(`/api/response/${responseId}/complete`);
    dispatch({ type: SAVING_TASK_REPONSE, payload: false });
  } catch (error) {
    console.log(error);
  }
};

// Reset State
export const resetStudy = () => dispatch => dispatch({ type: STUDY_COMPLETE });

// Update Test State
export const updateTestState = payload => dispatch => dispatch({ type: STUDY_TEST_UPDATE, payload });
