import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Header, Container, Image, Grid, Modal, Button, GridRow, Icon, List } from 'semantic-ui-react';
import { verifyTest, saveTaskResponse, markTestComplete, resetStudy, updateTestState } from '../../actions/study';
import { UXARMY_LOGO, CONFIRMATION_ICON, INSTRUCTIONS_ICON, DEFINE_ANSWER, SELECT_ANSWER } from '../../utills/icons';
import { treeMotion } from '../../utills/treeFunctions';
import UXLoader from '../layouts/UXLoader';
import '../../assets/css/study.css';

// RC Tree
import 'rc-tree/assets/index.css';
import Tree, { TreeNode } from 'rc-tree';

const StudyBegin = ({
  study: { participant, test, messages, isSavingTask, isPreview, testData },
  saveTaskResponse,
  markTestComplete,
  resetStudy,
  updateTestState
}) => {
  const { showGuideModal, showConfirmationModal, taskIndex, taskAnswer } = testData;
  const tasks = test.tasks || [];
  const treeData = test.tree.treeData || [];
  const responseId = participant.responseId;

  // Reset State
  useEffect(() => {
    return () => resetStudy();
  }, [resetStudy]);

  // Default Expanded Keys && Open home node by default
  const homeNodeId = [treeData[0].id];
  const [defaultExpandedNodes, setDefaultExpandedNodes] = useState(homeNodeId);

  // Test Interrupetd if test & participant not available.
  if (!participant || !participant.responseId || !test) {
    return <Redirect to="/treestudy/interrupted" />;
  }

  const task = tasks[taskIndex] ? tasks[taskIndex] : null;

  // Tree Setup
  const nodeAction = node => (
    <Fragment>
      {node.name}
      <div className="node-actions">
        <Button primary onClick={() => onAnswerSelect(node)}>
          I'll get it here
        </Button>
      </div>
    </Fragment>
  );
  const loopTreeNodes = data => {
    return data.map(item => {
      const isLeaf = item.children.length > 0 ? false : true;
      if (item.children) {
        return (
          <TreeNode title={nodeAction(item)} key={item.id} isLeaf={isLeaf} className={isLeaf ? 'leaf-el' : 'parent-el'}>
            {loopTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode title={nodeAction(item)} key={item.id} isLeaf={isLeaf} className={isLeaf ? 'leaf-el' : 'parent-el'} />;
    });
  };
  const treeNodes = loopTreeNodes(treeData);
  const switcherIcon = obj => {
    if (obj.isLeaf === true) return '';
    return <Icon name={obj.expanded === true ? 'caret down' : 'caret right'} />;
  };

  // On Answer Select: Save the Respone & Redirect to Next Task
  const onAnswerSelect = node => {
    taskAnswer.endTime = new Date().getTime();
    taskAnswer.answer.push({ id: node.id, label: node.name });

    saveTaskResponse(responseId, taskAnswer, isPreview);
    setDefaultExpandedNodes(homeNodeId);

    if (tasks[taskIndex + 1]) {
      updateTestState({ ...testData, taskIndex: taskIndex + 1, isNew: true });
    } else {
      markTestComplete(responseId, isPreview);
      updateTestState({ ...testData, showConfirmationModal: true });
    }
  };

  // Save user path
  const onExpand = (expanded, node) => {
    expanded && taskAnswer.answer.push({ id: node.props.eventKey, label: node.props.title.props.children[0] });
    updateTestState({ ...testData, taskAnswer });
  };

  if (!task || isSavingTask) {
    return <UXLoader />;
  }

  return (
    <Fragment>
      {isPreview && (
        <Container className="preview-mode p-2" fluid textAlign="center">
          You are currently in preview mode for the study. No data will be saved.
        </Container>
      )}
      <Grid className={`study no-navbar ${isPreview ? 'pt-4' : ''}`}>
        <Grid.Row className="study-header">
          <Grid.Column>
            <Image src={UXARMY_LOGO} size="small" />
            <Header as="h3" className="mt-2">
              Task {taskIndex + 1} of {tasks.length}
              <Header.Subheader className="mt-1">{task.description}</Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="study-body">
          <Grid.Column width="16">
            <Tree
              motion={treeMotion}
              switcherIcon={switcherIcon}
              showIcon={false}
              onExpand={(expandedKeys, { expanded, node }) => {
                onExpand(expanded, node);
                setDefaultExpandedNodes(expandedKeys);
              }}
              expandedKeys={defaultExpandedNodes}
            >
              {treeNodes}
            </Tree>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal size="small" open={showGuideModal} className="guide-modal">
        <Modal.Header>
          <Header as="h3">
            <Image src={INSTRUCTIONS_ICON} size="mini" className="mr-2" />
            Guide of Study
            <Header.Subheader className="mt-2">{messages.instructions}</Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Container className="p-2 mb-2">
            <List ordered>
              <List.Item>
                Complete simple Pre/Post Study Questions and also Pre/Post Task Questions before you get into a study & task or after
                you completed a task & study given.
              </List.Item>
              <List.Item>Read through the task carefully, define and select an answer.</List.Item>
              <List.Item>Repeat the same steps as follow till you complete the whole study.</List.Item>
            </List>
            <GridRow>
              <Image src={DEFINE_ANSWER} size="medium" inline label="Image 1.1: Define Answer" />
              <Icon name="arrow right" className="ml-1 mr-1" color="blue" />
              <Image src={SELECT_ANSWER} size="medium" inline label="Image 1.2: Select an Answer" />
            </GridRow>
          </Container>
          <Button primary fluid content="Got It!" onClick={() => updateTestState({ ...testData, showGuideModal: false })} />
        </Modal.Content>
      </Modal>

      <Modal size="mini" open={showConfirmationModal}>
        <Modal.Content scrolling>
          <Image src={CONFIRMATION_ICON} size="tiny" centered />
          <Header as="h2" textAlign="center" className="mt-2">
            Congratulations Buddy!
          </Header>
          <Container textAlign="center" className="p-2 mb-2" style={{ fontSize: '1.2rem' }}>
            {messages.thankyou}
          </Container>
          <Button primary fluid content="Back to Home" as={Link} to="/" />
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

StudyBegin.propTypes = {
  verifyTest: PropTypes.func.isRequired,
  saveTaskResponse: PropTypes.func.isRequired,
  markTestComplete: PropTypes.func.isRequired,
  resetStudy: PropTypes.func.isRequired,
  updateTestState: PropTypes.func.isRequired,
  study: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  study: state.study
});

export default connect(
  mapStateToProps,
  { verifyTest, saveTaskResponse, markTestComplete, resetStudy, updateTestState }
)(StudyBegin);
